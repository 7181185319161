import { Injectable } from '@angular/core';
import { SharedataService } from '../sharedata/sharedata.service';

@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  constructor(private sharedata:SharedataService) { }

  setData(userdata){
    sessionStorage.setItem('email',userdata.data.customer_email)
    sessionStorage.setItem('userId',userdata.data._id)
    sessionStorage.setItem('token',userdata.token)
    sessionStorage.setItem('name',userdata.data.customer_name)
    sessionStorage.setItem('is_social',userdata.is_social_reg)
    this.sharedata.setName(1)
  }

  public getId(){
    return sessionStorage.getItem('userId')
  }

  public getToken(){
    return sessionStorage.getItem('token')
  }

  public getName(){
    return sessionStorage.getItem('name')
  }

  public getEmail(){
    return sessionStorage.getItem('email')
  }

  public getIsSocial(){
    return sessionStorage.getItem('is_social')
  }

  public getCartData(){
    return sessionStorage.getItem('finalCartData')
  }

  public getCartTotal(){
    return sessionStorage.getItem('finalCartTotal')
  }

  public getRestaurantId(){
    return sessionStorage.getItem('restId')
  }

  //----------Store email for OTP verification for register customer
  public setVerifyEmail(data){
    sessionStorage.setItem('verifyEmail',data)
  }

  public getVerifyEmail(){
    return sessionStorage.getItem('verifyEmail')
  }

  public removeVerifyEmail(){
    sessionStorage.removeItem('verifyEmail')
  }

  public emptyCart(){
    sessionStorage.removeItem('restId')
    sessionStorage.removeItem('finalCartTotal')
    sessionStorage.removeItem('finalCartData')
    sessionStorage.removeItem('order_instructions')
  }

  public removeData(){
    sessionStorage.removeItem('email')
    sessionStorage.removeItem('userId')
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('name')
    sessionStorage.removeItem('is_social')
    this.sharedata.setName(0)
  }
}
