import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from '../shared/checkout/checkout.service';

@Component({
  selector: 'app-offers',
  templateUrl: './offers.component.html',
  styleUrls: ['./offers.component.css']
})
export class OffersComponent implements OnInit {

  offerList = []
  imageURL = ''
  constructor(private checkout:CheckoutService, public toast:ToastrService, private spinner:NgxSpinnerService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl:any) {
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.getOffers()
  }

  getOffers(){
    this.spinner.show()
    this.checkout.getOffers({}).subscribe(
      (res:any)=>{
        this.offerList = res.data
        //console.log(this.offerList)
        this.spinner.hide()
      }, err=>{
        this.spinner.hide()
        this.toast.error('Unable to fetch Offers.', 'Error')
        console.log(err)
      }
    )
  }

  public getSanitizerUrl(photoname:any) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }
}
