import { Component, Inject, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RestaurantService } from '../shared/restaurant/restaurant.service';

@Component({
  selector: 'app-restaurant-list',
  templateUrl: './restaurant-list.component.html',
  styleUrls: ['./restaurant-list.component.css']
})
export class RestaurantListComponent implements OnInit {

  imageURL = ''
  constructor(private restaurant: RestaurantService, private toast:ToastrService, private spinner: NgxSpinnerService, private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl:any) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.getAllRestaurants()
  }

  restList = []
  getAllRestaurants(){
    this.spinner.show()
    this.restaurant.allrestaurant({}).subscribe(
      (res:any) =>{
        this.restList = res.data
        this.spinner.hide()
      },err =>{
        this.spinner.hide()
        console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname:any) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

}
