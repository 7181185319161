import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../shared/search/search.service';

@Component({
  selector: 'app-footer-page',
  templateUrl: './footer-page.component.html',
  styleUrls: ['./footer-page.component.css']
})
export class FooterPageComponent implements OnInit {

  globalCatList = []
  constructor(private search:SearchService, private toast: ToastrService, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.getGlobalCategory()
  }

  getGlobalCategory(){
    this.search.fetchGlobalCat({}).subscribe(
      (res:any) =>{
        this.globalCatList = res.data
        //console.log(res.data)
      },err =>{
        //console.log(err)
      }
    )
  }

}
