<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Restaurants Near You</h1>
    <h6 class="text-white-50">Best deals at your favourite restaurants</h6>
    <div class="row m-0 p-4">
        <form class="offset-md-3 col-md-6 form-noborder" [formGroup]="searchForm">
            <div class="form-row">
                <!-- <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                    <div class="location-dropdown">
                        <i class="icofont-location-arrow"></i>
                        <select class="custom-select form-control-lg">
                            <option> Quick Searches </option>
                            <option> Breakfast </option>
                            <option> Lunch </option>
                            <option> Dinner </option>
                            <option> Cafés </option>
                            <option> Delivery </option>
                        </select>
                    </div>
                </div> -->
                <div class="col-lg-9 col-md-9 col-sm-12 form-group">
                    <input type="text" placeholder="Search Restaurant" class="form-control form-control-lg" formControlName="searchRest">
                    <!-- <a class="locate-me" href="javascript:void(0)"><i class="icofont-ui-pointer"></i> Locate Me</a> -->
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                    <button (click)="searchRest()" class="btn btn-primary btn-block btn-lg btn-gradient">Search</button>
                </div>
            </div>
        </form>
    </div>
</section>
<section class="section pt-5 pb-5 products-listing">
    <div class="container">
        <div class="row d-none-m">
            <div class="col-md-12">
                <!-- <div class="dropdown float-right">
                    <a class="btn btn-outline-info dropdown-toggle btn-sm border-white-btn" href="javascript:void(0)" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Sort by: <span class="text-theme">Distance</span> &nbsp;&nbsp;
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm border-0 ">
                        <a class="dropdown-item" href="javascript:void(0)">Distance</a>
                        <a class="dropdown-item" href="javascript:void(0)">No Of Offers</a>
                        <a class="dropdown-item" href="javascript:void(0)">Rating</a>
                    </div>
                </div> -->
                <h4 class="font-weight-bold mt-0 mb-3">RESTAURANTS <small class="h6 mb-0 ml-2">(Search: {{name}})  {{showRest.length}} Restaurants Found</small>
                </h4>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="row" *ngIf="showRest.length >0">
                    <div class="col-md-4 col-sm-6 mb-4 pb-2" *ngFor="let rest of showRest">
                        <div class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                            <div class="list-card-image">
                                <div class="star position-absolute"><span class="badge badge-success"><i
                                            class="icofont-star"></i> 3.1 (300+)</span></div>
                                <div class="favourite-heart text-danger position-absolute"><a [routerLink]="['/restaurant/',rest._id]"><i class="icofont-heart"></i></a></div>
                                <div class="member-plan position-absolute"><span
                                        class="badge badge-dark">Promoted</span></div>
                                <a [routerLink]="['/restaurant/',rest._id]">
                                    <img [src]="getSanitizerUrl(rest.logo)" class="img-fluid item-img">
                                </a>
                            </div>
                            <div class="p-3 position-relative">
                                <div class="list-card-body">
                                    <h6 class="mb-1"><a [routerLink]="['/restaurant/',rest._id]" class="text-black">{{rest.rest_name}}</a></h6>
                                    <p class="text-gray mb-3" *ngIf="rest.cuisines.length > 0">
                                        <span *ngFor="let cuisine of rest.cuisines" class="text-capitalize">
                                            {{cuisine.cuisineId.cuisine_name}} •
                                        </span>
                                    </p>
                                    <p class="text-gray mb-3 time"><span
                                            class="bg-light text-dark rounded-sm pl-2 pb-1 pt-1 pr-2"><i
                                                class="icofont-wall-clock"></i> 20–25 min</span> <span
                                            class="float-right text-black-50"> <a class="btn btn-sm btn-outline-info" [routerLink]="['/restaurant/',rest._id]">ORDER</a></span></p>
                                </div>
                                <div class="list-card-badge">
                                    <span class="badge badge-success">OFFER</span> <small>65% off | Use Coupon
                                        VEG50</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="showRest.length == 0">
                    <div class="col-md-12">
                        <h5 class="text-danger">No Restaurant Found.</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>