<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Offers for you</h1>
    <h6 class="text-white-50">Explore top deals and offers exclusively for you!</h6>
</section>
<section class="section pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h4 class="font-weight-bold mt-0 mb-3">Available Coupons</h4>
            </div>
            <div class="col-md-4" *ngFor="let offer of offerList">
                <div class="card offer-card border-0 shadow-sm">
                    <div class="card-body">
                        <h5 class="card-title"><img [src]="getSanitizerUrl(offer.offers_image)"> {{offer.offers_code}}</h5>
                        <h6 class="card-subtitle mb-2 text-block">{{offer.offers_name}}</h6>
                        <p class="card-text">{{offer.offer_desc}}. <span *ngIf="offer.maximum_discount > 0">Maximum discount: {{offer.maximum_discount | currency:'CAD':'symbol-narrow'}}</span></p>
                        <p class="card-text">Terms and Conditions apply. <i class="fa fa-info-circle text-danger" title="Order should be above {{offer.minimum_order | currency:'CAD':'symbol-narrow'}}"></i></p>
                        <a href="javascript:void(0)" (click)="toast.success('Offer Code Copied Successfuly','Success')" class="card-link" ngxClipboard [cbContent]="offer.offers_code">COPY CODE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>