import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SharedataService } from '../shared/sharedata/sharedata.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-topbar-page',
  templateUrl: './topbar-page.component.html',
  styleUrls: ['./topbar-page.component.css']
})
export class TopbarPageComponent implements OnInit {

  loggedIn= 0
  constructor(private sharedata: SharedataService, private userdata: UserdataService, private router: Router, private toast:ToastrService) { }

  ngOnInit(): void {
    this.sharedata.getName.subscribe(response=>{
      //console.log(response)
      this.loggedIn = Number(response)
    });
  }

  checkOutPage(){
    //console.log(this.userdata.getCartTotal())
    if(Number(this.userdata.getCartTotal()) > 0 && this.userdata.getCartTotal() != null && this.userdata.getCartTotal() != undefined && this.userdata.getCartTotal() != '' ){
      this.router.navigateByUrl('/checkout')
    }else{
      this.toast.warning('No Item In Cart','Oops')
    }
  }

  logOut(){
    this.userdata.removeData()
    this.userdata.emptyCart()
    this.router.navigateByUrl('/home')
  }

}
