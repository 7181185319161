import { Component, NgZone, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.css']
})
export class RegisterPageComponent implements OnInit {

  formGroup = new FormGroup({
    'customer_name': new FormControl('', [Validators.required]),
    'customer_password': new FormControl('', [Validators.required, Validators.minLength(6)]),
    'customer_email': new FormControl('', [Validators.required, Validators.email]),
    'customer_phone': new FormControl('', [Validators.required, Validators.pattern('[0-9]{10}')]),
    'is_social_reg': new FormControl(false),
    'terms': new FormControl(false, [Validators.requiredTrue]),
  })

  isPasswordMatched = false
  returnUrl: string;
  constructor(private route: ActivatedRoute,private spinner: NgxSpinnerService, private userdata: UserdataService, private toast: ToastrService, private userauth: UserauthService, private router: Router, private afAuth: AngularFireAuth,public ngZone: NgZone) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f() {
    return this.formGroup.controls
  }

  isPasswordConfirm(event) {
    console.log(event.target.value)
    if (this.formGroup.get('customer_password').value == event.target.value) {
      this.isPasswordMatched = true
    } else {
      this.isPasswordMatched = false
    }
  }

  checkTerms(event) {
    if (event.target.checked == true) {
      this.formGroup.patchValue({ 'terms': true })
    } else {
      this.formGroup.patchValue({ 'terms': false })
    }
  }

  onSubmit() {
    this.userauth.register(this.formGroup.value).subscribe(
      (res: any) => {
        this.userdata.setVerifyEmail(res.data.customer_email)
        this.toast.success(res.message, 'Success')
        this.userdata.setData(res)
        this.router.navigateByUrl('/verify')
      },
      err => {
        this.toast.error(err.error.message, 'Error')
        //console.log(err)
      }
    )
  }

  //----------------------------Social Register--------------------------//
  async GoogleAuth() {
    if (this.formGroup.get('terms').value == false) {
      Swal.fire('Error', 'Please accept terms and conditions.', 'error')
    }
    else {
      return this.AuthRegister(new auth.GoogleAuthProvider());
    }
  }

  async FacebookAuth() {
    if (this.formGroup.get('terms').value == false) {
      Swal.fire('Error', 'Please accept terms and conditions.', 'error')
    }
    else {
      return this.AuthRegister(new auth.FacebookAuthProvider());
    }
  }

  // Auth logic to run auth providers
  AuthRegister(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
      console.log(result)
      var data = {
        'customer_name': result.user.displayName,
        'customer_password': result.user.uid,
        'customer_email': result.user.email,
        'customer_phone': result.user.phoneNumber,
        'is_social_reg': true,
        'terms': true,
        'customer_pic': result.user.photoURL
      }
      this.userauth.register(data).subscribe(
        (res: any) => {
          this.userdata.setData(res)
          Swal.fire({
            title : 'Success',
            text : 'Login Successful',
            icon : 'success'
          }).then((res)=>{
            this.ngZone.run(() => {
              this.router.navigate(['home']);
            })
          })
        }, err => {  
          Swal.fire('Oops', err.error.message, 'error')
        }
      )
    }).catch((error) => {
      console.log(error)
    })
  }

}
