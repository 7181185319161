import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
  }

  public fetchCuisines(form){
    return this.http.post(this.BaseUrl + "/allcuisines",form)
  }

  public searchCuisines(form){
    return this.http.post(this.BaseUrl + "/allrestpublicforweb",form)
  }

  public fetchGlobalCat(form){
    return this.http.post(this.BaseUrl + "/allcategoriespublic",form)
  }

  public searchGlobalCat(form){
    return this.http.post(this.BaseUrl + "/restfromglobalcat",form)
  }

  public searchFoodType(form){
    var header_object = new HttpHeaders().set("Authorization",this.Token)
    return this.http.post(this.BaseUrl + "/vegbasedrest",form, { headers: header_object})
  }

  public searchRestaurant(form){
    return this.http.post(this.BaseUrl + "/searchregexcatandrest",form)
  }

  public fetchCity(form){
    return this.http.get(this.BaseUrl + "/getallcity",form)
  }

  public fetchServices(form){
    return this.http.get(this.BaseUrl + "/getallservices",form)
  }

  public fetchTags(form){
    return this.http.get(this.BaseUrl + "/getalltag",form)
  }

  public topRest(form){
    return this.http.post(this.BaseUrl + "/alltoprest",form)
  }
}
