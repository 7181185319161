<app-topbar-page></app-topbar-page>
<!--Content-->
<section class="pt-5 pb-5 homepage-search-block position-relative">
    <div class="banner-overlay"></div>
    <div class="container">
        <div class="row d-flex align-items-center">
            <div class="col-md-8">
                <div class="homepage-search-title">
                    <h1 class="mb-2 font-weight-normal"><span class="font-weight-bold">Find Awesome Deals</span> in
                        Canada</h1>
                    <h5 class="mb-5 text-secondary font-weight-normal">Lists of top restaurants, cafes, pubs, and bars in Canada, based on trends</h5>
                </div>
                <div class="homepage-search-form">
                    <form class="form-noborder" [formGroup]="searchForm">
                        <div class="form-row">
                            <!-- <div class="col-lg-3 col-md-3 col-sm-12 form-group">
                                <div class="location-dropdown">
                                    <i class="icofont-location-arrow"></i>
                                    <select class="custom-select form-control-lg">
                                        <option> Quick Searches </option>
                                        <option> Breakfast </option>
                                        <option> Lunch </option>
                                        <option> Dinner </option>
                                        <option> Cafés </option>
                                        <option> Delivery </option>
                                    </select>
                                </div>
                            </div> -->
                            <div class="col-lg-10 col-md-10 col-sm-12 form-group">
                                <input type="text" placeholder="Search Restaurant" class="form-control form-control-lg" formControlName="searchRest">
                                <!-- <a class="locate-me" href="javascript:void(0)"><i class="icofont-ui-pointer"></i> Locate Me</a> -->
                            </div>
                            <div class="col-lg-2 col-md-2 col-sm-12 form-group">
                                <button (click)="searchRest()" class="btn btn-primary btn-block btn-lg btn-gradient">Search</button>
                            </div>
                        </div>
                    </form>
                </div>
                <!-- <h6 class="mt-4 text-shadow font-weight-normal">E.g. Beverages, Pizzas, Chinese, Bakery, Indian...
                </h6> -->
                <div class="owl-carousel owl-carousel-category owl-theme">
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/1.png" alt="">
                                <h6>American</h6>
                                <p>156</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/2.png" alt="">
                                <h6>Pizza</h6>
                                <p>120</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/3.png" alt="">
                                <h6>Healthy</h6>
                                <p>130</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/4.png" alt="">
                                <h6>Vegetarian</h6>
                                <p>120</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/5.png" alt="">
                                <h6>Chinese</h6>
                                <p>111</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/6.png" alt="">
                                <h6>Hamburgers</h6>
                                <p>958</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/7.png" alt="">
                                <h6>Dessert</h6>
                                <p>56</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/8.png" alt="">
                                <h6>Chicken</h6>
                                <p>40</p>
                            </a>
                        </div>
                    </div>
                    <div class="item">
                        <div class="osahan-category-item">
                            <a href="javascript:void(0)">
                                <img class="img-fluid" src="../../assets/img/list/9.png" alt="">
                                <h6>Indian</h6>
                                <p>156</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="osahan-slider pl-4 pt-3">
                    <div class="owl-carousel homepage-ad owl-theme">
                        <div class="item">
                            <a href="javascript:void(0)"><img class="img-fluid rounded" src="../../assets/img/slider.png"></a>
                        </div>
                        <div class="item">
                            <a href="javascript:void(0)"><img class="img-fluid rounded" src="../../assets/img/slider1.png"></a>
                        </div>
                        <div class="item">
                            <a href="javascript:void(0)"><img class="img-fluid rounded" src="../../assets/img/slider.png"></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section pt-5 pb-5 bg-white homepage-add-section">
    <div class="container">
        <div class="row">
            <div class="col-md-3 col-6">
                <div class="products-box">
                    <a href="javascript:void(0)"><img alt="" src="../../assets/img/pro1.jpg" class="img-fluid rounded"></a>
                </div>
            </div>
            <div class="col-md-3 col-6">
                <div class="products-box">
                    <a href="javascript:void(0)"><img alt="" src="../../assets/img/pro2.jpg" class="img-fluid rounded"></a>
                </div>
            </div>
            <div class="col-md-3 col-6">
                <div class="products-box">
                    <a href="javascript:void(0)"><img alt="" src="../../assets/img/pro3.jpg" class="img-fluid rounded"></a>
                </div>
            </div>
            <div class="col-md-3 col-6">
                <div class="products-box">
                    <a href="javascript:void(0)"><img alt="" src="../../assets/img/pro4.jpg" class="img-fluid rounded"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section pt-5 pb-5 products-section">
    <div class="container">
        <div class="section-header text-center">
            <h2>Popular Restaurants</h2>
            <p>Top restaurants, cafes, pubs, and bars in Ludhiana, based on trends</p>
            <span class="line"></span>
        </div>
        <div class="row text-center d-flex justify-content-center" *ngIf="topRestList.length >0">
            <div class="col-md-3 col-6" *ngFor="let top of topRestList">
                <div class="products-box">
                    <a [routerLink]="['/restaurant',top.restId._id]"><img alt="" [src]="getSanitizerUrl(top.restId.logo)" class="img-fluid rounded img-toprest"></a>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="section pt-5 pb-5 bg-white becomemember-section border-bottom">
    <div class="container">
        <div class="section-header text-center white-text">
            <h2>Become a Member</h2>
            <p>Join VegFood to enhance your business opportunities.</p>
            <span class="line"></span>
        </div>
        <div class="row">
            <div class="col-sm-12 text-center">
                <a [routerLink]="['/register']" class="btn btn-success btn-lg">
                    Create an Account <i class="fa fa-chevron-circle-right"></i>
                </a>
            </div>
        </div>
    </div>
</section>
<!--//Content-->
<app-footer-page></app-footer-page>