import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarPageComponent } from './topbar-page/topbar-page.component';
import { HomePageComponent } from './home-page/home-page.component';
import { FooterPageComponent } from './footer-page/footer-page.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { RestaurantListComponent } from './restaurant-list/restaurant-list.component';
import { RestaurantDetailsComponent } from './restaurant-details/restaurant-details.component';
import { HttpClientModule } from "@angular/common/http";
//Animation
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
//Toastr
import { ToastrModule } from 'ngx-toastr';
//Spinner
import { NgxSpinnerModule } from "ngx-spinner";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckoutComponent } from './checkout/checkout.component';
import { CheckoutAddressComponent } from './checkout-address/checkout-address.component';
import { AgmCoreModule } from '@agm/core';
import { ThankyouOrderComponent } from './thankyou-order/thankyou-order.component';
import { UserComponent } from './user/user.component';
import { OffersComponent } from './offers/offers.component';
import { SearchrestComponent } from './searchrest/searchrest.component';
import { ClipboardModule } from 'ngx-clipboard';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { AddAddressComponent } from './add-address/add-address.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { RegisterRestaurantComponent } from './register-restaurant/register-restaurant.component';
import { TagInputModule } from 'ngx-chips';
import { NgToggleModule } from 'ng-toggle-button';
import { AddSupportComponent } from './add-support/add-support.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
//-----------------------Firebase Social Login-------------------------------//
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    TopbarPageComponent,
    FooterPageComponent,
    ErrorPageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    RestaurantListComponent,
    RestaurantDetailsComponent,
    CheckoutComponent,
    CheckoutAddressComponent,
    ThankyouOrderComponent,
    UserComponent,
    OffersComponent,
    SearchrestComponent,
    OrderDetailComponent,
    AddAddressComponent,
    EditAddressComponent,
    RegisterRestaurantComponent,
    AddSupportComponent,
    TermsConditionsComponent,
    ForgetpasswordComponent,
    VerifyOtpComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule, // required animations module
    AppRoutingModule,
    HttpClientModule,
    ToastrModule.forRoot(), // ToastrModule added
    NgxSpinnerModule, //Spinner
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCikrgrvaDgb61Zk1J4bbQ1CFcLX34BvkQ',
      libraries: ['places']
    }),
    ClipboardModule,
    TagInputModule, 
    NgToggleModule,
    NgOtpInputModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AngularFireAuth],
  bootstrap: [AppComponent]
})
export class AppModule { }
