import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl(){
  return "http://vegfood.moonwalkerapp.com"
  //return "http://192.168.1.107:8081"
} 

export function getBaseImageUrl(){
  return "http://vegfood.moonwalkerapp.com/"
  //return "http://192.168.1.107:8081/"
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] },
  { provide: 'BASE_IMAGE_URL', useFactory: getBaseImageUrl, deps: [] }
]

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.error(err));
