import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { SearchService } from '../shared/search/search.service';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  imageURL = ''
  constructor(private router: Router, private search:SearchService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl) {
    this.imageURL = _imageurl
   }

  ngOnInit(): void {
    this.topRestaurant()
  }

  //---------Search
  searchForm = new FormGroup({
    searchRest : new FormControl('')
  })
  searchRest(){
    this.router.navigateByUrl('/search/'+this.searchForm.get('searchRest').value)
  }

  //Top restaurant List
  topRestList = []
  topRestaurant(){
    this.search.topRest({}).subscribe(
      (res:any) => {
        this.topRestList = res.toprest[0].top_rest
        //console.log(this.topRestList)
      },
      err => {
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

}
