<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="offer-dedicated-body mt-4 mb-4 pt-2 pb-2">
    <div class="container">
        <form class="row" [formGroup]="orderForm" (submit)="placeOrder()">
            <div class="col-md-8">
                <div class="offer-dedicated-body-left">
                    <div class="bg-white rounded shadow-sm p-4 mb-4">
                        <div class="col-12 d-flex justify-content-between">
                            <h4 class="mb-1">Choose a delivery address</h4>
                            <a class="btn btn-sm btn-primary mr-2" [routerLink]="['/checkout-address']"> ADD NEW
                                ADDRESS</a>
                        </div>
                        <h6 class="ml-3 mb-3 text-black-50">Multiple addresses in this location</h6>
                        <div class="row" [class.disabled-div]="!isDelivery">
                            <div class="col-md-12 text-center" *ngIf="addressList.length == 0">
                                <h5><i class="icofont-close-line text-danger"></i>No Address Found!</h5>
                            </div>
                            <div class="col-md-6" *ngFor="let address of addressList">
                                <div class="bg-white card addresses-item mb-4 border">
                                    <div class="gold-members p-4 address-div">
                                        <div class="media">
                                            <div class="mr-3">
                                                <i class="icofont-ui-home icofont-3x"></i>
                                            </div>
                                            <div class="media-body">
                                                <h6 class="mb-1 text-black">{{address.address_name}}</h6>
                                                <p class="text-black">{{address.address_full}}</p>
                                                <p class="mb-0 text-black font-weight-bold">
                                                    <button type="button"  (click)="changeSelectedAddress(address._id,address.customer_lat,address.customer_long)" class="btn btn-sm btn-success mr-2 btn-address" [id]="address._id"
                                                        *ngIf="address._id == defaultAddress"> DELIVER HERE</button>
                                                    <button type="button"  (click)="changeSelectedAddress(address._id,address.customer_lat,address.customer_long)" class="btn btn-sm btn-secondary mr-2 btn-address" [id]="address._id"
                                                        *ngIf="address._id != defaultAddress"> DELIVER HERE</button>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="pt-2"></div>
                    <div class="bg-white rounded shadow-sm p-4 osahan-payment">
                        <h4 class="mb-1">Choose payment method</h4>
                        <h6 class="mb-3 text-black-50">Credit/Debit Cards Or Cash</h6>
                        <div class="row">
                            <div class="alert alert-danger col-12" *ngIf="noAddress">
                                Select Address To Proceed
                            </div>
                            <div class="alert alert-danger col-12" *ngIf="subTotal == 0">
                                No Item In Cart. Please Select Item to order.
                            </div>
                        </div>
                        <div class="row" [class.disabled-div]="subTotal == 0 || noAddress">
                            <div class="col-sm-4 pr-0">
                                <div class="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                    aria-orientation="vertical">
                                    <a class="nav-link active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-home" role="tab" aria-controls="v-pills-home" aria-selected="true"><i class="icofont-credit-card"></i> Credit/Debit Cards</a>
                                    
                                    <a [class.disabled-a]="!isDelivery || !isCod" class="nav-link" id="v-pills-cash-tab" data-toggle="pill" href="#v-pills-cash"
                                        role="tab" aria-controls="v-pills-cash" aria-selected="false"><i
                                            class="icofont-money"></i> Pay on Delivery</a>
                                </div>
                            </div>
                            <div class="col-sm-8 pl-0">
                                <div class="tab-content h-100" id="v-pills-tabContent">
                                    <div class="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                        aria-labelledby="v-pills-home-tab">
                                        <h6 class="mb-3 mt-0 mb-3">Add new card</h6>
                                        <p>WE ACCEPT <span class="osahan-card">
                                                <i class="icofont-visa-alt"></i> <i class="icofont-mastercard-alt"></i>
                                                <i class="icofont-american-express-alt"></i> <i
                                                    class="icofont-payoneer-alt"></i> <i
                                                    class="icofont-apple-pay-alt"></i> <i
                                                    class="icofont-bank-transfer-alt"></i> <i
                                                    class="icofont-discover-alt"></i> <i class="icofont-jcb-alt"></i>
                                            </span>
                                        </p>
                                        <form #checkout="ngForm" (ngSubmit)="onSubmit(checkout)" class="checkout">
                                            <div class="form-row">
                                              <label for="card-info">Card Info</label>
                                              <div id="card-info" #cardInfo></div>
                                          
                                              <div id="card-errors" role="alert" *ngIf="error">{{ error }}</div>
                                            </div>
                                          
                                            <button type="submit">Pay {{grandTotal |currency:'CAD':'symbol-narrow'}}</button>
                                        </form>
                                    </div>
                                    
                                    <div class="tab-pane fade" id="v-pills-cash" role="tabpanel"
                                        aria-labelledby="v-pills-cash-tab">
                                        <h6 class="mb-3 mt-0 mb-3">Cash</h6>
                                        <p>Please keep exact change handy to help us serve you better</p>
                                        <hr>
                                            <button [disabled]="!orderForm.valid || !isDelivery || !isCod" class="btn btn-success btn-block btn-lg" type="submit">PAY {{grandTotal |currency:'CAD':'symbol-narrow'}}<i class="icofont-long-arrow-right"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                    <div class="d-flex mb-4 osahan-cart-item-profile">
                        <img class="img-fluid mr-3 rounded-pill img-rest-logo" [alt]="restData.rest_name"
                            [src]="getSanitizerUrl(restData.logo)">
                        <div class="d-flex flex-column">
                            <h6 class="mb-1 text-white">{{restData.rest_name}}
                            </h6>
                            <p class="mb-0 text-white"><i class="icofont-location-pin"></i> {{restData.rest_address}}
                            </p>
                        </div>
                    </div>
                    <div class="bg-white rounded shadow-sm mb-2">
                        <div class="gold-members p-2 border-bottom" *ngFor="let detail of cartData; let i = index">
                            <p class="text-gray mb-0 float-right ml-2">{{detail.pprice |
                                currency:'CAD':'symbol-narrow'}}</p>
                            <span class="count-number float-right">
                                <button type="button" class="btn btn-outline-secondary  btn-sm left" (click)="removeDataOrder(0,i)">
                                    <i class="icofont-minus"></i> </button>
                                <input class="count-number-input" type="text" [value]="detail.qty" readonly="">
                                <button type="button" class="btn btn-outline-secondary btn-sm right inc"
                                    (click)="removeDataOrder(1,i)"> <i class="icofont-plus"></i>
                                </button>
                            </span>
                            <div class="media">
                                <div class="mr-2"><i class="icofont-ui-press text-success food-item"></i></div>
                                <div class="media-body">
                                    <p class="mt-1 mb-0 text-black">{{detail.product_name}}</p>
                                </div>
                            </div>
                            <div class="dish-ingredients">
                                <div *ngFor="let addon of detail.addOn">
                                    <span class="check-box" *ngFor="let subadd of addon.value">
                                        <span class="text-gray">{{subadd.valuetype}}</span>
                                        <i class="text-gray">${{subadd.valuesvalue}}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 bg-white rounded p-2 clearfix">
                        <div class="btn-group btn-group-toggle d-flex justify-content-center" data-toggle="buttons">
                            <label class="btn btn-info active" (click)="isDeliveryOrTakeway(false)">
                                <input type="radio" name="order_takeaway" id="option1" autocomplete="off" checked value="false"> Delivery
                            </label>
                            <label class="btn btn-info" (click)="isDeliveryOrTakeway(true)">
                                <input type="radio" name="order_takeaway" id="option2" autocomplete="off" value="true"> Take Away
                            </label>
                        </div>
                    </div>
                    <div class="mb-2 bg-white rounded p-2 clearfix">
                        <div class="input-group input-group-sm mb-2">
                            <input type="text" class="form-control" placeholder="Enter promo code" formControlName="coupons_code">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="button-addon2" (click)="applyCoupon()"><i
                                        class="icofont-sale-discount"></i> APPLY</button>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between mb-2" *ngIf="couponApplied">
                            <span>Coupon <label class="text-primary">{{this.couponDetails.offer_code}}</label> applied!</span>
                            <span class="icofont-close-circled text-danger" (click)="removeDiscount()"></span>
                        </div>
                        <div class="input-group mb-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icofont-comment"></i></span>
                            </div>
                            <textarea class="form-control" placeholder="Any suggestions for Kitchen" formControlName="order_instructions" aria-label="With textarea"></textarea>
                        </div>
                    </div>
                    <div class="mb-2 bg-white rounded p-2 clearfix" *ngIf="isDelivery">
                        <label for="">Tip Amount</label>
                        <div class="input-group input-group-sm mb-2">
                            <input type="text" class="form-control tip" placeholder="Enter Tip Amount" formControlName="tip_amount" maxlength="4">
                            <div class="input-group-append">
                                <button class="btn btn-primary" type="button" id="button-addon2" (click)="addTip()"><i class="icofont-bill"></i> APPLY</button>
                            </div>
                        </div>
                        <hr/>
                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="contactLess" (change)="isContactLess($event)">
                            <label class="custom-control-label" for="contactLess">Do You Want Contact-Less Delivery? </label>
                        </div>
                        <div class="custom-control custom-checkbox mb-3">
                            <input type="checkbox" class="custom-control-input" id="bellRing" (change)="isBellToRing($event)">
                            <label class="custom-control-label" for="bellRing">Don't Ring the Bell. </label>
                        </div>
                        <div class="input-group mb-0">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="icofont-comment"></i></span>
                            </div>
                            <textarea class="form-control" placeholder="Any suggestions for Delivery" formControlName="driver_instructions" aria-label="With textarea"></textarea>
                        </div>
                    </div>
                    <div class="mb-2 bg-white rounded p-2 clearfix">
                        <p class="mb-1">Item Total <span class="float-right text-dark">{{subTotal | currency:'CAD':'symbol-narrow'}}</span></p>
                        <p class="mb-1">Tax Charges <span class="float-right text-dark">{{tax | currency:'CAD':'symbol-narrow'}}</span></p>
                        <p class="mb-1">Discount<span class="float-right text-dark">{{discount | currency:'CAD':'symbol-narrow'}}</span>
                        </p>
                        <p class="mb-1 text-success">Sub Total<span class="float-right text-success">{{productWithTaxDiscount |currency:'CAD':'symbol-narrow'}}</span>
                        </p>
                        <p class="mb-1">Delivery Fee <span class="float-right text-dark">{{deliveryCharge |currency:'CAD':'symbol-narrow'}}</span>
                        </p>
                        <p class="mb-1">Tip Amount <span class="float-right text-dark">{{tip |currency:'CAD':'symbol-narrow'}}</span>
                        </p>
                        <hr />
                        <h6 class="font-weight-bold mb-0">TO PAY <span class="float-right">{{grandTotal |currency:'CAD':'symbol-narrow'}}</span></h6>
                    </div>
                </div>
                <div class="pt-2"></div>
                <div class="text-center pt-2">
                    <img class="img-fluid" src="https://dummyimage.com/352x504/ccc/ffffff.png&amp;text=Google+ads">
                </div>
            </div>
        </form>
    </div>
</section>
<app-footer-page></app-footer-page>