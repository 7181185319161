<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Order Details</h1>
    <h6 class="text-white-50">Order #{{orderDetails.orderId}}</h6>
</section>
<nav aria-label="breadcrumb">
    <ol class="breadcrumb">
        <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/home']">Home</a></li>
        <li class="breadcrumb-item"><a href="javascript:void(0)" [routerLink]="['/account']">Orders</a></li>
        <li class="breadcrumb-item active" aria-current="page">Details</li>
    </ol>
</nav>
<section class="section pt-5 pb-5">
    <div class="container">
        <div class="bg-white p-4 shadow-lg mt-2 mb-4">
            <div class="row text-center">
                <div class="col">
                    <i class="icofont-tasks icofont-3x text-info"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Order Received</p>
                </div>
                <div class="col" *ngIf="orderDetails.order_current_status==104 ||orderDetails.order_current_status==110 ">
                    <i class="icofont-close-circled icofont-3x text-danger"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Order Declined</p>
                </div>
                <div class="col" *ngIf="orderDetails.order_current_status==102">
                    <i class="icofont-pay icofont-3x text-danger"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Payment Declined</p>
                </div>
                <div class="col" *ngIf="orderDetails.order_current_status==103 || orderDetails.order_current_status==105 || orderDetails.order_current_status==106 || orderDetails.order_current_status==107 || orderDetails.order_current_status==108">
                    <i class="icofont-food-cart icofont-3x text-warning"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Cooking....</p>
                </div>
                <div class="col" *ngIf="orderDetails.order_current_status==105 || orderDetails.order_current_status==106 || orderDetails.order_current_status==107 || orderDetails.order_current_status==108">
                    <i class="icofont-restaurant icofont-3x text-primary"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Cooked</p>
                </div>
                <div class="col" *ngIf="(orderDetails.order_current_status==106 || orderDetails.order_current_status==107 || orderDetails.order_current_status==108) && !orderDetails.order_takeaway">
                    <i class="icofont-map-pins icofont-3x text-info"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Pickup</p>
                </div>
                <div class="col" *ngIf="(orderDetails.order_current_status==107 || orderDetails.order_current_status==108) && !orderDetails.order_takeaway">
                    <i class="icofont-delivery-time icofont-3x text-primary"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">On-Way</p>
                </div>
                <div class="col" *ngIf="orderDetails.order_current_status==108">
                    <i class="icofont-culinary icofont-3x text-success"></i>
                    <p class="mt-1 font-weight-bold text-dark mb-0">Served!</p>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="p-5 osahan-invoice bg-white shadow-sm">
                    <div class="row mb-5 pb-3 ">
                        <div class="col-md-8 col-10">
                            <h3 class="mt-0">Thanks for choosing <strong class="text-secondary">Veg Food</strong>,
                                {{customer_name}} ! Here are your order details: </h3>
                        </div>
                        <div class="col-md-4 col-2 pl-0 text-right">
                            <p class="mb-4 mt-2">
                                <!-- <a class="text-primary font-weight-bold" href="#"><i class="icofont-print"></i>
                                    PRINT</a> -->
                            </p>
                            <!-- <img alt="logo" src="../../assets/img/favicon.png"> -->
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <p class="mb-1 text-black">Order No: <strong># {{orderDetails.orderId}}</strong></p>
                            <p class="mb-1">Order placed at: <strong>{{orderDetails.created_at |
                                    date:'medium'}}</strong></p>
                            <!-- <p class="mb-1">Order delivered at: <strong>12/11/2018, 07:18 PM</strong></p> -->
                            <p class="mb-1">Order Status:
                                <strong class="text-success" *ngIf="orderDetails.order_current_status == 101">Order Placed</strong>
                                <strong class="text-danger" *ngIf="orderDetails.order_current_status == 102">Payment Declined</strong>
                                <strong class="text-info" *ngIf="orderDetails.order_current_status == 103">Cooking...</strong>
                                <strong class="text-danger" *ngIf="orderDetails.order_current_status == 104 || orderDetails.order_current_status == 110">Order Rejected</strong>
                                <strong class="text-success" *ngIf="orderDetails.order_current_status == 105">Cooked</strong>
                                <strong class="text-info" *ngIf="orderDetails.order_current_status == 106">Pick Up</strong>
                                <strong class="text-info" *ngIf="orderDetails.order_current_status == 107">On-Way</strong>
                                <strong class="text-success" *ngIf="orderDetails.order_current_status == 108">Delivered</strong>
                                <strong class="text-danger" *ngIf="orderDetails.order_current_status == 109">Cancelled</strong>
                                <strong class="text-danger" *ngIf="orderDetails.order_current_status == 111">Refunded</strong>
                            </p>
                        </div>
                        <div class="col-md-6">
                            <p class="mb-1 text-black">Delivery To:</p>
                            <p class="mb-1 text-primary" *ngIf="orderDetails.customerId != null">
                                <strong>{{orderDetails.customerId.customer_name}}</strong></p>
                            <p class="mb-1" *ngIf="orderDetails.addressId != null">
                                {{orderDetails.addressId.address_full}}
                            </p>
                        </div>
                    </div>
                    <div class="row mt-5">
                        <div class="col-md-12">
                            <p class="mb-1">Ordered from:</p>
                            <h6 class="mb-1 text-black" *ngIf="orderDetails.restId != null">
                                <strong>{{orderDetails.restId.rest_name}}</strong></h6>
                            <p class="mb-1" *ngIf="orderDetails.restId != null">{{orderDetails.restId.rest_address}}</p>
                            <table class="table mt-3 mb-0">
                                <thead class="thead-light">
                                    <tr>
                                        <th class="text-black font-weight-bold" scope="col">Item Name</th>
                                        <th class="text-right text-black font-weight-bold" scope="col">Quantity</th>
                                        <th class="text-right text-black font-weight-bold" scope="col">Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let detail of productDetails; let i = index">
                                        <td>
                                            {{detail.product_name}}
                                            (<span *ngFor="let addon of detail.addOn">
                                                <span *ngFor="let subadd of addon.value">{{subadd.valuetype}} ,</span>
                                            </span>)
                                        </td>
                                        <td class="text-right">{{detail.qty}}</td>
                                        <td class="text-right">{{detail.pprice | currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="2">Item Total:</td>
                                        <td class="text-right"> {{orderDetails.sub_total |
                                            currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetails.is_coupon">
                                        <td class="text-right" colspan="2">Discount Applied
                                            ({{orderDetails.coupons_code}}):</td>
                                        <td class="text-right"> {{orderDetails.coupon_discount |
                                            currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="2">Tax:</td>
                                        <td class="text-right"> {{orderDetails.tax_amount |
                                            currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="2">Delivery Charges:</td>
                                        <td class="text-right"> {{orderDetails.del_charges |
                                            currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr *ngIf="orderDetails.tip_amount > 0">
                                        <td class="text-right" colspan="2">Tip:</td>
                                        <td class="text-right"> {{orderDetails.tip_amount |
                                            currency:'CAD':'symbol-narrow'}}</td>
                                    </tr>
                                    <tr>
                                        <td class="text-right" colspan="2">
                                            <h6 class="text-success">Grand Total:</h6>
                                        </td>
                                        <td class="text-right">
                                            <h6 class="text-success"> {{orderDetails.total_paid | currency:'CAD':'symbol-narrow'}}</h6>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="row" *ngIf="orderDetails.is_rating_done && orderDetails.order_current_status == 108">
                    <div class="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                        <h5 class="mb-2 text-white text-center">Your Rating</h5>
                        <div class="bg-white rounded shadow-sm mb-2">
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [rate]="orderDetails.rest_rating" readonly="true">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                          </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">How likely did you love your Food?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [rate]="orderDetails.driver_rating" readonly="true">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                          </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">Satisfied with the delivery?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [rate]="orderDetails.system_rating" readonly="true">
                                    <ng-template let-fill="fill" let-index="index">
                                        <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                      </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">How was your overall experience?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <form class="row" *ngIf="!orderDetails.is_rating_done && orderDetails.order_current_status == 108">
                    <div class="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                        <h5 class="mb-2 text-white text-center">Help Us improve Our Service.</h5>
                        <p class="mb-4 text-white text-center">Please Rate Us!</p>
                        <div class="bg-white rounded shadow-sm mb-2">
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [formControl]="restRate">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                          </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">How likely did you love your Food?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [formControl]="driverRate">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                          </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">Satisfied with the delivery?</p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <ngb-rating [max]="5" [formControl]="systemRate">
                                        <ng-template let-fill="fill" let-index="index">
                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                          </ng-template>
                                    </ngb-rating>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">How was your overall experience?</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <a href="javascript:void(0)" class="btn btn-success btn-block btn-lg" (click)="rateOrder()">Rate <i class="icofont-long-arrow-right"></i></a>
                    </div>
                </form>
                <div class="row">
                    <div class="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item">
                        <h5 class="mb-2 text-white text-center">Other Details</h5>
                        <div class="bg-white rounded shadow-sm mb-2">
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Order Type:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            <span *ngIf="orderDetails.order_takeaway">Take Away</span>
                                            <span *ngIf="!orderDetails.order_takeaway">Delivery</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center" *ngIf="orderDetails.order_instructions != ''">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Kitchen Instructions:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            {{orderDetails.order_instructions}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center" *ngIf="orderDetails.driver_instructions != ''">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Delivery Instructions:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            {{orderDetails.driver_instructions}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Payment Mode:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            <span *ngIf="orderDetails.is_online">Credit/Debit Card</span>
                                            <span *ngIf="orderDetails.is_cod">Pay On Delivery</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center" *ngIf="orderDetails.is_order_contact_less">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Is Order ContactLess ?:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            Yes
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center" *ngIf="!orderDetails.is_bell_to_ring">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Don't Ring the bell ? :</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            Yes
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="gold-members p-2 border-bottom text-center" *ngIf="orderDetails.order_cancel_reason_rest != ''">
                                <p class="text-gray mb-0 ml-2">
                                    <strong>Restaurant Order cancellation Reason:</strong>
                                </p>
                                <div class="media">
                                    <div class="media-body">
                                        <p class="mt-1 mb-0 text-black">
                                            <span>{{orderDetails.order_cancel_reason_rest}}</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>