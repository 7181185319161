import { MapsAPILoader, MouseEvent } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-checkout-address',
  templateUrl: './checkout-address.component.html',
  styleUrls: ['./checkout-address.component.css']
})
export class CheckoutAddressComponent implements OnInit {

  latitude: number;

  longitude: number;

  zoom: number;

  address: string;

  private geoCoder;



  @ViewChild('search')
  public searchElementRef: ElementRef;

  formGroup = new FormGroup({
    address_name: new FormControl('', [Validators.required]),
    customerId: new FormControl(''),
    //pincode: new FormControl('', [Validators.required]),
    customer_lat: new FormControl('', [Validators.required]),
    customer_long: new FormControl('', [Validators.required]),
    address_full: new FormControl('', [Validators.required])
  })

  get f(){
    return this.formGroup.controls
  }

  constructor(private mapsAPILoader: MapsAPILoader,private ngZone: NgZone, private userdata:UserdataService,private spinner: NgxSpinnerService, private router: Router, private toast: ToastrService, private userauth: UserauthService) { }


  ngOnInit() {
    this.formGroup.patchValue({customerId:this.userdata.getId()})
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.zoom = 12;
          this.getAddress(this.latitude,this.longitude)
        });
      });
    });
  }



  setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }



  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
          this.formGroup.patchValue({address_full:this.address})
          this.formGroup.patchValue({customer_lat:latitude})
          this.formGroup.patchValue({customer_long:longitude
          })
          //this.formGroup.patchValue({pincode:results[0].address_components[9].long_name})
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  markerDragEnd($event: MouseEvent) {
    this.latitude = $event.coords.lat;
    this.longitude = $event.coords.lng;
    this.getAddress(this.latitude, this.longitude);
  }

  public onSubmit(){
    this.spinner.show();
    this.formGroup.patchValue({'customerId':this.userdata.getId()})
    this.userauth.addAddress(this.formGroup.value).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.router.navigateByUrl('/checkout')
      },
      err => {
        this.spinner.hide();
        this.toast.error(err.error.message,'Error')
        //console.log(err)
      }
    )
  }

}
