import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  BaseUrl = ''
  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) { 
    this.BaseUrl = _base
  }

  public myOrders(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/myorders",form, { headers: header_object})
  }

  public singleOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/fetchsingleorderbyid",form, { headers: header_object})
  }

  public rateOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/ratemyorder",form, { headers: header_object})
  }

  public cancelOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/cancelorder",form, { headers: header_object})
  }

  public issueSupport(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/addnewissue",form, { headers: header_object})
  }

  //----Get Support for Order Id
  public supportForOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/getissuefromorderid",form, { headers: header_object})
  }
}
