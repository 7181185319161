<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <div class="row no-gutter">
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div class="col-md-8 col-lg-6">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5">
                            <h3 class="login-heading mb-4">New Buddy!</h3>
                            <form [formGroup]="formGroup" (submit)="onSubmit()">
                                <div class="form-label-group">
                                    <input formControlName="customer_email" type="email" id="inputEmail" class="form-control" placeholder="Email address">
                                    <label for="inputEmail">Email address</label>
                                    <small class="form-text" *ngIf="f.customer_email.invalid && (f.customer_email.dirty || f.customer_email.touched)">
                                        <small *ngIf="f.customer_email.errors">Invalid Email</small>
                                    </small>
                                </div>

                                <div class="form-label-group">
                                    <input formControlName="customer_name" type="text" class="form-control" placeholder="Name" id="inputName">
                                    <label for="inputName">Name</label>
                                    <small class="form-text" *ngIf="f.customer_name.invalid && (f.customer_name.dirty || f.customer_name.touched)">
                                        <small *ngIf="f.customer_name.errors">Required</small>
                                    </small>
                                </div>

                                <div class="form-label-group mb-4">
                                    <input formControlName="customer_phone" type="text" id="ptext" class="form-control" placeholder="Phone Number">
                                    <label for="ptext">Phone Number</label>
                                    <small class="form-text" *ngIf="f.customer_phone.invalid && (f.customer_phone.dirty || f.customer_phone.touched)">
                                        <small *ngIf="f.customer_phone.errors">Invalid Phone Number</small>
                                    </small>
                                </div>
                                <div class="form-label-group">
                                    <input formControlName="customer_password" type="password" id="inputPassword" class="form-control"
                                        placeholder="Password">
                                    <label for="inputPassword">Password</label>
                                    <small class="form-text" *ngIf="f.customer_password.invalid && (f.customer_password.dirty || f.customer_password.touched)">
                                        <small *ngIf="f.customer_password.errors">Required minimum 6 characters</small>
                                    </small>
                                </div>

                                <div class="form-label-group">
                                    <div class="custom-control custom-checkbox">
                                        <input type="checkbox" (change)="checkTerms($event)">
                                        <label for="customCheck1"> &nbsp;I accept <a [routerLink]="['/terms']">Terms & Conditions</a> of VegFood.</label>
                                    </div>
                                </div>
                                
                                <button type="submit" class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" [disabled]="!formGroup.valid">Sign Up</button>
                                <hr class="my-4">
                                <p class="text-center">SIGN UP WITH</p>
                                <div class="row">
                                    <div class="col pr-2">
                                        <button
                                            class="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase"
                                            type="button" (click)="GoogleAuth()"><i class="fab fa-google mr-2"></i> Google</button>
                                    </div>
                                    <div class="col pl-2">
                                        <button
                                            class="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase"
                                            type="button" (click)="FacebookAuth()"><i class="fab fa-facebook-f mr-2"></i> Facebook</button>
                                    </div>
                                </div>
                                <div class="text-center pt-3">
                                    Already have an Account? <a class="font-weight-bold" [routerLink]="['/login']">Sign In</a>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>