<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Edit Delivery Address</h1>
</section>
<section class="section pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-8 mx-auto">
                <div class="p-5 osahan-invoice bg-white shadow-sm">
                    <div class="row mb-3">
                        <div class="col-md-12 col-12">
                            <h3 class="mt-0">Edit Delivery Address:</h3>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <form [formGroup]="formGroup" class="row" (submit)="onSubmit()">
                                <div class="form-row">
                                    <div class="form-group col-md-12">
                                        <label for="inputPassword4">Search Address</label>
                                        <div class="input-group">
                                            <input type="text" class="form-control" placeholder="Search Address" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                        </div>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="inputPassword4">Complete Address
                                        </label>
                                        <input type="text" class="form-control" placeholder="Complete Address" name="address_full" id="address_full" formControlName="address_full" [readonly]="true">
                                        <small class="form-text" *ngIf="f.address_full.invalid && (f.address_full.dirty || f.address_full.touched)">
                                            <small *ngIf="f.address_full.errors.required">Invalid Value</small>
                                        </small>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="inputPassword4">Complete Address
                                        </label>
                                        <agm-map [latitude]="lat" [longitude]="lng">
                                            <agm-marker *ngFor="let m of markers; let i = index"
                                            [latitude]="m.lat"
                                            [longitude]="m.lng"
                                            [label]="m.label"
                                            [markerDraggable]="m.draggable"
                                            (dragEnd)="markerDragEnd(m, $event)"></agm-marker>
                                        </agm-map>
                                    </div>
                                    <div class="form-group col-md-12">
                                        <label for="inputPassword4">Nickname
                                        </label>
                                        <input type="text" class="form-control" placeholder="Nickname e.g. Home, Work, Other" name="address_name" id="address_name" formControlName="address_name">
                                        <small class="form-text" *ngIf="f.address_name.invalid && (f.address_name.dirty || f.address_name.touched)">
                                            <small *ngIf="f.address_name.errors.required">Invalid Value</small>
                                        </small>
                                    </div>
                                    <div class="form-group col-md-12 text-center">
                                        <button type="submit" class="btn btn-success" [disabled]="!formGroup.valid">Save</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>