import { MapsAPILoader,MouseEvent } from '@agm/core';
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-edit-address',
  templateUrl: './edit-address.component.html',
  styleUrls: ['./edit-address.component.css']
})
export class EditAddressComponent implements OnInit {

  id=''
  //-----------Map
  lat = 50.8101141;
  lng = -130.1748436;
  markerLat = 47.414589
  markerLong= -82.4560307
  private geoCoder; 
  address: string;
  
  markerDragEnd(m: marker, $event: MouseEvent) {
    //console.log('dragEnd', m, $event);
    this.formGroup.patchValue({'customer_lat':$event.coords.lat})
    this.formGroup.patchValue({'customer_long':$event.coords.lng})
    console.log(this.formGroup.get('customer_lat').value)
    console.log(this.formGroup.get('customer_long').value)
    this.getAddress($event.coords.lat, $event.coords.lng)
  }

  markers: marker[] = [
	  {
		  lat: this.markerLat,
		  lng: this.markerLong,
		  label: 'A',
		  draggable: true
	  }
  ]

  @ViewChild('search')
  public searchElementRef: ElementRef;

  formGroup = new FormGroup({
    address_name: new FormControl('', [Validators.required]),
    customerId: new FormControl(''),
    //pincode: new FormControl('', [Validators.required]),
    customer_lat: new FormControl('', [Validators.required]),
    customer_long: new FormControl('', [Validators.required]),
    address_full: new FormControl('', [Validators.required]),
    _id : new FormControl('',[Validators.required])
  })


  get f(){
    return this.formGroup.controls
  }

  constructor(private mapsAPILoader: MapsAPILoader,private ngZone: NgZone,private toast: ToastrService, private userauth : UserauthService, private router: Router, private userdata: UserdataService, private activate: ActivatedRoute,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({customerId:this.userdata.getId()})
    this.formGroup.patchValue({_id:this.id})
    this.getSingleAddress()
    // this.mapsAPILoader.load().then(() => {
    //   this.geoCoder = new google.maps.Geocoder;

    //   let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
    //   autocomplete.addListener("place_changed", () => {
    //     this.ngZone.run(() => {
    //       let place: google.maps.places.PlaceResult = autocomplete.getPlace();
    //       if (place.geometry === undefined || place.geometry === null) {
    //         return;
    //       }

    //       this.lat = place.geometry.location.lat();
    //       this.lng = place.geometry.location.lng();
    //       this.getAddress(this.lat,this.lng)
    //     });
    //   });
    // });
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.address = results[0].formatted_address;
          this.formGroup.patchValue({address_full:this.address})
          this.formGroup.patchValue({customer_lat:latitude})
          this.formGroup.patchValue({customer_long:longitude})
          this.markers[0].lat = Number(latitude)
          this.markers[0].lng = Number(longitude)
          //this.formGroup.patchValue({pincode:results[0].address_components[9].long_name})
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  getSingleAddress(){
    this.userauth.singleAddress({'_id':this.id}).subscribe(
      (res: any) => {
        this.spinner.hide();
        this.formGroup.patchValue({address_name: res.data.address_name});     
        this.formGroup.patchValue({customer_lat: res.data.customer_lat});     
        this.formGroup.patchValue({customer_long: res.data.customer_long});     
        this.formGroup.patchValue({address_full: res.data.address_full}); 
        //console.log('rest id---'+this.formGroup.get('restId').value)
        if(res.data.customer_lat != "" && res.data.customer_long != ""){
          this.markers[0].lat = Number(res.data.customer_lat)
          this.markers[0].lng = Number(res.data.customer_long)
          this.lat = Number(res.data.customer_lat)
          this.lng = Number(res.data.customer_long)
        }
        //console.log(res.data.customer_lat,res.data.customer_long)
        this.f   
      },
      err => {
        this.spinner.hide();
        this.toast.error('Something Went Wrong','Error')
        console.log(err)
      }
    )
  }

  onSubmit(){
    this.userauth.updateAddress(this.formGroup.value).subscribe(
      (res:any)=>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/account')
      },err=>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
// just an interface for type safety.
interface marker {
	lat: number;
	lng: number;
	label?: string;
	draggable: boolean;
}

