<!--------Modal------------->
<ng-template #content let-modal>
    <div class="modal-header generator-bg">
        <h4 class="modal-title text-white" id="modal-basic-title">Choose Your Order</h4>
        <!-- <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="modal-body row m-0 generator-bg">
        <div *ngFor="let addons of modalData.addon" class="col-12 bg-white">
            <div class="text-capitalize" *ngIf="addons.addontype == 'Price'">Choose One (Required)
            </div>
            <div class="text-capitalize" *ngIf="addons.addontype != 'Price'">{{addons.addontype}}</div>
            <div>
                <ul class="addon-ul" *ngIf="addons.addontype=='Price'">
                    <li *ngFor="let moreadon of addons.addonvalue; let i = index;" class="text-capitalize"><input
                            type="radio" [name]="addons.addontype" [value]="moreadon"
                            (change)="changeAddon($event,moreadon, addons.addontype, modalData)"
                            name="{{addons.addontype}}" /> {{moreadon.valuename}} - ${{moreadon.valuedetail}}</li>
                </ul>
                <ul class="addon-ul" *ngIf="addons.addontype!='Price'">
                    <li *ngFor="let moreadon of addons.addonvalue; let i = index;"><input type="radio"
                            [name]="addons.addontype" [value]="moreadon"
                            (change)="changeAddon($event,moreadon, addons.addontype, modalData)"
                            name="{{addons.addontype}}" /> {{moreadon.valuename}} - ${{moreadon.valuedetail}}</li>
                    <li><input type="radio" [name]="addons.addontype" value="none"
                            (change)="changeAddon($event,'none', addons.addontype, modalData)"
                            name="{{addons.addontype}}" /> None</li>
                </ul>
            </div>
            <hr>
        </div>
        <div class="col-12 d-flex justify-content-between bg-white">
            <div class="p-2">
                Quantity: 
                <button class="btn btn-outline-secondary  btn-sm left" (click)="incDecQuantity(0,modalData)"> <i class="icofont-minus"></i> </button>
                <input class="count-number-addon" type="text" value="1" readonly="" [(ngModel)]="quantity" (change)="keyFunc(modalData)" [ngModelOptions]="{standalone: true}" min="1">
                <button class="btn btn-outline-secondary btn-sm right" (click)="incDecQuantity(1,modalData)"> <i class="icofont-plus"></i> </button>
            </div>
            <div class="p-2">{{cartTotal | currency :'CAD':'symbol-narrow'}}</div>
        </div>
    </div>
    <div class="modal-footer generator-bg">
        <button type="button" class="btn btn-outline-dark" (click)="modal.close('Close click')">Add To Cart</button>
        <button type="button" class="btn btn-outline-dark" (click)="modal.dismiss('Close click')">Cancel</button>
    </div>
</ng-template>
<!--------------//Modal---------------->
<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="restaurant-detailed-banner">
    <div class="text-center">
        <img class="img-fluid cover" src="../../assets/img/mall-dedicated-banner.png">
    </div>
    <div class="restaurant-detailed-header">
        <div class="container">
            <div class="row d-flex align-items-end">
                <div class="col-md-8">
                    <div class="restaurant-detailed-header-left">
                        <img class="img-fluid mr-3 float-left rest-logo" alt="osahan"
                            [src]="getSanitizerUrl(restDetails.logo)">
                        <h2 class="text-white">{{restDetails.rest_name}}</h2>
                        <p class="text-white mb-1"><i class="icofont-location-pin"></i>{{restDetails.rest_address}}
                            <span *ngIf="isRestaurantActive" class="badge badge-success"> OPEN</span><span
                                *ngIf="!isRestaurantActive" class="badge badge-danger"> CLOSED</span>
                        </p>
                        <p class="text-white mb-0"><i class="icofont-food-cart"></i> 
                            <span *ngFor="let cuisine of restDetails.cuisines">
                                {{cuisine.cuisineId.cuisine_name}} | 
                            </span>
                        </p>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="restaurant-detailed-header-right text-right" *ngIf="restDetails.rest_rating != null">
                        <h6 class="text-white mb-0 restaurant-detailed-ratings"><span
                                class="generator-bg rounded text-white"><i class="icofont-star"></i> {{restDetails.rest_rating}}</span></h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="offer-dedicated-nav bg-white border-top-0 shadow-sm">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <span class="restaurant-detailed-action-btn float-right">
                    <button *ngIf="!hasMatch" class="btn btn-light btn-sm border-light-btn" type="button"
                        (click)="addToFav()"><i class="icofont-heart text-danger"></i> Mark as Favourite</button>
                    <button *ngIf="hasMatch" class="btn btn-light btn-sm border-light-btn" type="button"
                        (click)="addToFav()"><i class="icofont-heart text-danger"></i> Favourite</button>
                    <button class="btn btn-light btn-sm border-light-btn" type="button"><i
                            class="icofont-cauli-flower text-success"></i> Pure Veg</button>
                </span>
                <ul class="nav" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link active" id="pills-order-online-tab" data-toggle="pill"
                            href="#pills-order-online" role="tab" aria-controls="pills-order-online"
                            aria-selected="true">Order Online</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" id="pills-gallery-tab" data-toggle="pill" href="#pills-gallery" role="tab"
                            aria-controls="pills-gallery" aria-selected="false">Gallery</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" id="pills-restaurant-info-tab" data-toggle="pill"
                            href="#pills-restaurant-info" role="tab" aria-controls="pills-restaurant-info"
                            aria-selected="false">Restaurant Info</a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link" id="pills-book-tab" data-toggle="pill" href="#pills-book" role="tab"
                            aria-controls="pills-book" aria-selected="false">Book A Table</a>
                    </li> -->
                    <li class="nav-item">
                        <a class="nav-link" id="pills-reviews-tab" data-toggle="pill" href="#pills-reviews" role="tab"
                            aria-controls="pills-reviews" aria-selected="false">Ratings & Reviews</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="offer-dedicated-body pt-2 pb-2 mt-4 mb-4">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <div class="offer-dedicated-body-left">
                    <div class="tab-content" id="pills-tabContent">
                        <div class="tab-pane fade show active" id="pills-order-online" role="tabpanel"
                            aria-labelledby="pills-order-online-tab">
                            <div id="#menu" class="bg-white rounded shadow-sm p-4 mb-4 explore-outlets">
                                <h5 class="mb-4">Menu</h5>
                            </div>
                            <div class="row" *ngFor="let prod of proDetail">
                                <h5 class="mb-4 mt-3 col-md-12" *ngIf="prod.pro.length > 0">{{prod.rest_cat_name}}
                                    <small class="h6 text-black-50">{{prod.pro.length}} ITEMS</small>
                                </h5>
                                <div class="col-md-12" *ngIf="prod.pro.length > 0">
                                    <div class="bg-white rounded border shadow-sm mb-4">
                                        <div class="menu-list p-3 border-bottom" *ngFor="let product of prod.pro">
                                            <span class="count-number float-right">
                                                <button class="btn btn-outline-secondary btn-sm  float-right" (click)="open(content,product)" *ngIf="product.status=='Enabled'" [disabled]="!isRestaurantActive || !isRestaurantAcceptingOrder">ADD</button>
                                                <small *ngIf="!isRestaurantActive || !isRestaurantAcceptingOrder" class="text-danger">Restaurant Not accepting Orders &nbsp;</small>
                                            </span>
                                            <div class="media">
                                                <img class="mr-3 rounded-pill"
                                                    [src]="getSanitizerUrl(product.product_image)"
                                                    alt="User image">
                                                <div class="media-body">
                                                    <h6 class="mb-1">{{product.product_name}}</h6>
                                                    <p class="text-gray mb-0" *ngFor="let price of product.addon">
                                                        <span *ngIf="price.addontype == 'Price'">
                                                            {{findMinMax(price.addonvalue) | currency
                                                            :'CAD':'symbol-narrow'}}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-restaurant-info" role="tabpanel"
                            aria-labelledby="pills-restaurant-info-tab">
                            <div id="restaurant-info" class="bg-white rounded shadow-sm p-4 mb-4">
                                <div class="address-map float-right ml-5">
                                    <div class="mapouter">
                                        <div class="gmap_canvas"><iframe width="300" height="170" id="gmap_canvas" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe></div>
                                    </div>
                                </div>
                                <h5 class="mb-4">Restaurant Info</h5>
                                <p class="mb-3">{{restDetails.rest_address}}
                                </p>
                                <p class="mb-2 text-black"><i
                                        class="icofont-phone-circle text-primary mr-2"></i>{{restDetails.rest_phone}}
                                </p>
                                <p class="mb-2 text-black"><i
                                        class="icofont-email text-primary mr-2"></i>{{restDetails.person_email}}</p>
                                <!-- <p class="mb-2 text-black"><i class="icofont-clock-time text-primary mr-2"></i>
                                    Today 11am – 5pm, 6pm – 11pm
                                    <span class="badge badge-success" *ngIf="isRestaurantActive"> OPEN NOW </span>
                                    <span class="badge badge-danger" *ngIf="!isRestaurantActive"> CLOSED </span>
                                </p> -->
                                <hr class="clearfix">
                                <h5 class="mt-4 mb-4">More Info</h5>
                                <p class="mb-3" *ngIf="cuisineList.length >0">
                                    <span *ngFor="let cuisine of cuisineList" class="text-capitalize">{{cuisine.cuisineId.cuisine_name}}, </span>
                                </p>
                                <div class="border-btn-main mb-4" *ngIf="serviceList.length >0">
                                    <a class="border-btn text-success mr-2" href="javascript:void(0)" *ngFor="let service of serviceList"><i class="icofont-check-circled"></i> {{service.serviceId.name}}</a>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="pills-reviews" role="tabpanel"
                            aria-labelledby="pills-reviews-tab">
                            <div id="ratings-and-reviews"
                                class="bg-white rounded shadow-sm p-4 mb-4 clearfix restaurant-detailed-star-rating">
                                <span class="star-rating float-right">
                                    <a href="javascript:void(0)"><i class="icofont-ui-rating icofont-2x active"></i></a>
                                    <a href="javascript:void(0)"><i class="icofont-ui-rating icofont-2x active"></i></a>
                                    <a href="javascript:void(0)"><i class="icofont-ui-rating icofont-2x active"></i></a>
                                    <a href="javascript:void(0)"><i class="icofont-ui-rating icofont-2x active"></i></a>
                                    <a href="javascript:void(0)"><i class="icofont-ui-rating icofont-2x"></i></a>
                                </span>
                                <h5 class="mb-0 pt-1">Rate this Place</h5>
                            </div>
                            <div class="bg-white rounded shadow-sm p-4 mb-4 restaurant-detailed-ratings-and-reviews">
                                <!-- <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm float-right">Top Rated</a> -->
                                <h5 class="mb-1">All Ratings and Reviews</h5>
                                <div *ngIf="reviewList.length <=0">
                                    <div class="reviews-members pt-4 pb-4">
                                        <div class="media">
                                            <div class="media-body">
                                                <div class="reviews-members-header">
                                                    <h6 class="mb-1"><a class="text-black" href="javascript:void(0)">No
                                                            Review Yet!</a>
                                                    </h6>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngFor="let review of reviewList">
                                    <div class="reviews-members pt-4 pb-4">
                                        <div class="media">
                                            <a href="javascript:void(0)">
                                                <img alt="Image"
                                                    [src]="review.customerId.customer_pic"
                                                    class="mr-3 rounded-pill" *ngIf="review.customerId?.is_social_reg">
                                                    <img alt="Image"
                                                    [src]="getSanitizerUrl(review.customerId.customer_pic)"
                                                    class="mr-3 rounded-pill" *ngIf="!review.customerId?.is_social_reg">
                                                </a>
                                            <div class="media-body">
                                                <div class="reviews-members-header">
                                                    <span class="star-rating float-right">
                                                        <ngb-rating [rate]="review.rest_rating" [max]="5"><ng-template let-fill="fill" let-index="index">
                                                            <span class="star" [class.filled]="fill === 100" [class.bad]="index < 3">&#9733;</span>
                                                          </ng-template></ngb-rating>
                                                    </span>
                                                    <h6 class="mb-1">{{review.customerId.customer_name}}
                                                    </h6>
                                                    <p class="text-gray">Tue, 20 Mar 2020</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="generator-bg rounded shadow-sm mb-4 p-4 osahan-cart-item" *ngIf="cartData.length > 0 && isRestaurantActive && isRestaurantAcceptingOrder">
                    <h5 class="mb-1 text-white">Your Order</h5>
                    <p class="mb-4 text-white">{{cartData.length}} ITEMS</p>
                    <div class="bg-white rounded shadow-sm mb-2">
                        <div class="gold-members p-2 border-bottom" *ngFor="let detail of cartData; let i = index">
                            <p class="text-gray mb-0 float-right ml-2">{{detail.pprice | currency:'CAD':'symbol-narrow'}}</p>
                            <span class="count-number float-right">
                                <button class="btn btn-outline-secondary  btn-sm left" (click)="removeDataOrder(0,i)"> <i class="icofont-minus"></i> </button>
                                <input class="count-number-input" type="text" [value]="detail.qty" readonly="">
                                <button class="btn btn-outline-secondary btn-sm right inc" (click)="removeDataOrder(1,i)"> <i class="icofont-plus"></i>
                                </button>
                            </span>
                            <div class="media">
                                <div class="mr-2"><i class="icofont-ui-press text-success food-item"></i></div>
                                <div class="media-body">
                                    <p class="mt-1 mb-0 text-black">{{detail.product_name}}</p>
                                </div>
                            </div>
                            <div class="dish-ingredients">
                                <div *ngFor="let addon of detail.addOn">
                                    <span class="check-box" *ngFor="let subadd of addon.value">
                                        <span class="text-gray">{{subadd.valuetype}}</span>
                                        <i class="text-gray">${{subadd.valuesvalue}}</i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-2 bg-white rounded p-2 clearfix">
                        <img class="img-fluid float-left" src="../../assets/img/wallet-icon.png">
                        <h6 class="font-weight-bold text-right mb-2">Subtotal : <span class="text-danger">{{finalCartTotal | currency:'CAD':'symbol-narrow'}}</span>
                        </h6>
                        <p class="seven-color mb-1 text-right">Extra charges may apply</p>
                        <!-- <p class="text-black mb-0 text-right">You have saved $955 on the bill</p> -->
                    </div>
                    <a (click)="addToCart()" href="javascript:void(0)" class="btn btn-success btn-block btn-lg" *ngIf="finalCartTotal > 0">Checkout <i class="icofont-long-arrow-right"></i></a>
                </div>
                <div class="text-center pt-2 mb-4">
                    <img class="img-fluid" src="https://dummyimage.com/352x600/ccc/ffffff.png&amp;text=Google+ads">
                </div>
                <div class="text-center pt-2">
                    <img class="img-fluid" src="https://dummyimage.com/352x568/ccc/ffffff.png&amp;text=Google+ads">
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>