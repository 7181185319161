import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddAddressComponent } from './add-address/add-address.component';
import { AddSupportComponent } from './add-support/add-support.component';
import { AuthGuard } from './auth/auth.guard';
import { CheckoutAddressComponent } from './checkout-address/checkout-address.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { HomePageComponent } from './home-page/home-page.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { OffersComponent } from './offers/offers.component';
import { OrderDetailComponent } from './order-detail/order-detail.component';
import { RegisterPageComponent } from './register-page/register-page.component';
import { RegisterRestaurantComponent } from './register-restaurant/register-restaurant.component';
import { RestaurantDetailsComponent } from './restaurant-details/restaurant-details.component';
import { RestaurantListComponent } from './restaurant-list/restaurant-list.component';
import { SearchrestComponent } from './searchrest/searchrest.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { ThankyouOrderComponent } from './thankyou-order/thankyou-order.component';
import { UserComponent } from './user/user.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';

const routes: Routes = [
  {path:'',component:HomePageComponent},
  //Login & Register
  {path:'login',component:LoginPageComponent},
  {path:'register',component:RegisterPageComponent},

  //Verify OTP
  {path:'verify',component:VerifyOtpComponent},
  //Basic Routes
  {path:'home',component:HomePageComponent},

  //Show Restaurants
  {path:'restaurants',component:RestaurantListComponent},
  {path:'restaurant/:id',component:RestaurantDetailsComponent},

  //Offers  
  {path:'offers',component:OffersComponent},

  //Issue  
  {path:'issue/:id',component:AddSupportComponent},

  //------------Search Routes----------//
  {path:'search/:name',component:SearchrestComponent},

  //Checkout
  {path:'checkout',component:CheckoutComponent,canActivate:[AuthGuard]},

  //Add Checkout-Address
  {path:'checkout-address',component:CheckoutAddressComponent,canActivate:[AuthGuard]},

  //Thankyou For Order
  {path:'orderPlaced',component:ThankyouOrderComponent,canActivate:[AuthGuard]},

  //My Account
  {path:'account',component:UserComponent,canActivate:[AuthGuard]},

  //Order Details
  {path:'order/:id',component:OrderDetailComponent,canActivate:[AuthGuard]},

  //Add Address
  {path:'add-address',component:AddAddressComponent,canActivate:[AuthGuard]},

  //Edit Address
  {path:'edit-address/:id',component:EditAddressComponent,canActivate:[AuthGuard]},

  //Register Restaurant
  {path:'register/restaurant',component:RegisterRestaurantComponent},

  //Terms and conditions
  {path:'terms',component:TermsConditionsComponent},

  //Forget Password
  {path:'forgetPassword',component:ForgetpasswordComponent},

  {path:'**',component:ErrorPageComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
