import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  BaseUrl = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl, private userdata:UserdataService) {
    this.BaseUrl = baseUrl
  }

  public allrestaurant(form:any){
    return this.http.post(this.BaseUrl + "/allrestpublic",form)
  }

  public singleRestaurant(form:any){
    return this.http.post(this.BaseUrl + "/singlerestforpublic",form)
  }

  public getReviews(form:any){
    return this.http.post(this.BaseUrl + "/fetcheachreview",form)
  }

  public getRestaurantProduct(form){
    return this.http.post(this.BaseUrl + "/fetchproducts",form)
  }

  public markFav(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/insertfavrest",form, { headers: header_object})
  }

  public showFav(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/showfavrest",form, { headers: header_object})
  }

  public registerRestaurant(form){
    return this.http.post(this.BaseUrl + "/rest/selfrestregister",form)
  }

}
