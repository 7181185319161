import { Component, Inject, OnInit, ɵSWITCH_TEMPLATE_REF_FACTORY__POST_R3__ } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2'
import { AddonData, Cart, Value } from '../model/cart.model';
import { RestaurantService } from '../shared/restaurant/restaurant.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-restaurant-details',
  templateUrl: './restaurant-details.component.html',
  styleUrls: ['./restaurant-details.component.css']
})
export class RestaurantDetailsComponent implements OnInit {

  imageURL = ''
  cartData: Cart[] = []
  finalCartTotal = 0
  constructor(private router:Router,private modalService: NgbModal,private userdata: UserdataService,private restaurant:RestaurantService, private activate:ActivatedRoute, private spinner: NgxSpinnerService, private toast: ToastrService, private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl:any) { 
    this.imageURL = _imageurl
  }

  id:any = ''
  proDetail: any = []
  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.getSingleRestaurant()
    this.getReviews()
    this.getProducts()
    this.showFav()
    if(sessionStorage.getItem('finalCartData') != null && (sessionStorage.getItem('restId') == this.id)){
      this.cartData = JSON.parse(sessionStorage.getItem('finalCartData'))
      this.finalCartTotal = Number(sessionStorage.getItem('finalCartTotal'))
    }
  } 

  restDetails:any = ''
  isRestaurantActive = true
  isRestaurantAcceptingOrder = true
  isRestaurantAcceptingBooking = true
  mapLink:any = ''
  cuisineList = []
  serviceList = []
  getSingleRestaurant(){
    this.spinner.show()
    this.restaurant.singleRestaurant({'_id':this.id}).subscribe(
      (res:any) =>{
        this.spinner.hide()
        //console.log(res)
        this.restDetails = res.data
        this.cuisineList = res.data.cuisines
        this.serviceList = res.data.services
        this.isRestaurantActive = res.data.is_current_active
        this.mapLink = "https://maps.google.com/maps?q="+res.data.rest_lat+","+res.data.rest_long+"&z=15&output=embed"
        if(res.data.setting_type == 'Takeaway'){
          this.isRestaurantAcceptingOrder = true
          this.isRestaurantAcceptingBooking = false
        }
        if(res.data.setting_type == 'Din-in'){
          this.isRestaurantAcceptingOrder = false
          this.isRestaurantAcceptingBooking = true
        }
        if(res.data.setting_type == 'Both'){
          this.isRestaurantAcceptingOrder = true
          this.isRestaurantAcceptingBooking = true
        }
      },err =>{
        this.spinner.hide()
        //console.log(err.error.message)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  //--------Get Restaurant Reviews------------//
  reviewList = []
  getReviews(){
    this.restaurant.getReviews({'restId':this.id}).subscribe(
      (res:any) => {
        //console.log(res.review)
        this.reviewList = res.review
      },
      err => {
        // console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  //-----------Get Restaurant Products---------//
  getProducts() {
    this.restaurant.getRestaurantProduct({ 'restId': this.id }).subscribe(
      (res: any) => {
        //console.log(res)
        this.proDetail = res.data
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message, 'Error')
      }
    )
  }

  findMinMax(arr) {
    var array = arr;

    var res = Math.min.apply(Math, array.map(function (o) { return o.valuedetail; }))

    var obj = array.find(function (o) { return o.valuedetail == res; })

    //alert(JSON.stringify(obj.valuedetail))

    return obj.valuedetail;
  }

  //---------- Add to Fave -------------//
  addToFav(){
    if(this.userdata.getToken() == null)
    {
      this.toast.info("Login Required","Info:")
    }
    else{
      this.restaurant.markFav({'restId':this.id,'customerId':this.userdata.getId()}).subscribe(
        (res:any) => {
          this.getSingleRestaurant()
          this.showFav()
          this.toast.success(res.message,'Success')
        },
        err => {
          console.log(err)
          this.toast.error(err.error.message,'Error')
        }
      )
    }
  }

  //--------Get fave rest----------//
  allFav
  hasMatch = false
  showFav(){
    this.hasMatch = false
    this.restaurant.showFav({'customerId':this.userdata.getId()}).subscribe(
      (res:any) => {
        this.allFav = res.data.myfav_rest
        for (var index = 0; index < this.allFav.length; ++index) {
          var data = this.allFav[index];
          if(data.restId._id == this.id){
            this.hasMatch = true;
            break;
          }
        }
      },
      err => {
        //console.log(err)
        //this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname:any) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  //-------------Modal Code-----------//
  closeResult: string;
  modalData
  quantity: number = 1;
  cartTotal = 0
  
  tempCart: Cart[] = []
  

  open(content,data) {
    this.modalData = data;
    this.quantity = 1
    var tempItem: Cart = new Cart
    tempItem.pid = String(data._id)
    tempItem.product_name = data.product_name
    tempItem.qty = 1
    tempItem.catid = String(data.catId)
    tempItem.addOn = []
    this.tempCart.push(tempItem)
    this.modalService.open(content, { ariaLabelledBy: 'fades', size: 'lg','backdrop':false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      var selProd = this.tempCart[0].addOn.find(p => p.addontype == 'Price');
      if(selProd != undefined){
        this.cartData.push(tempItem)
        //  console.log(this.cartData)
      }
      else
      {
        Swal.fire('Oops','Price is required','error')
      }
      this.tempCart.splice(0,this.tempCart.length)
      this.finalCartTotal = this.getCartTotal()
      this.cartTotal = 0
    }, (reason) => {
      this.cartTotal = 0
      //  console.log(this.cartData)
      this.finalCartTotal = this.getCartTotal()
      this.tempCart.splice(0,this.tempCart.length)
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  changeAddon(e, value, type, modalData) {

    if(value == 'none')
    {
      var selProd = this.tempCart[0].addOn.find(p => p.addontype == type);
      if (selProd != undefined) {

        var j = this.tempCart[0].addOn.indexOf(selProd)
        this.tempCart[0].addOn.splice(j,1)
      }
    }
    else{
      var data: AddonData = new AddonData
      var val: Value = new Value
      data.addontype = type
      val.valuetype = value.valuename
      val.valuesvalue = value.valuedetail
      data.value = []
      data.value.push(val)
      var selProd = this.tempCart[0].addOn.find(p => p.addontype == type);
      //console.log(selProd)
      if (selProd != undefined) {

        var j = this.tempCart[0].addOn.indexOf(selProd)
        this.tempCart[0].addOn.splice(j,1)
      }
      this.tempCart[0].addOn.push(data)
    }
  //  console.log(this.tempCart[0].addOn)
    this.keyFunc(modalData)
  }

  incDecQuantity(val,modalData){
    if(val == 0){
      if(this.quantity != 1){
        this.quantity -= 1
      }
    }else{
      this.quantity += 1
    }
    this.keyFunc(modalData)
  }

  keyFunc(modaldata){
    if(this.quantity != null || this.quantity > 0)
    {
      this.tempCart[0].qty = this.quantity
      var sum = this.calculations(modaldata._id)
      this.cartTotal = sum * this.quantity
      this.tempCart[0].pprice = String(this.cartTotal)
    }
    this.finalCartTotal = this.getCartTotal()
    return 0
  }

  calculations(id):number{
    var sum = 0
      this.tempCart[0].addOn.forEach(function(value) {
        value.value.forEach(function(value1) {
          sum = sum + parseFloat(value1.valuesvalue)
        })
      })
    return sum
  }

  //----------Get Cart Total------------//
  getCartTotal(){
    var total = 0
    this.cartData.forEach(function(element) {
      total = Number(total) + Number(element.pprice)
    })
    return total
  }
  //------Remove data from my orders------//
  removeDataOrder(val,id){
    if(val == 1){
      this.cartData[id].qty += 1 
      var sum = 0
        this.cartData[id].addOn.forEach(function(value) {
          value.value.forEach(function(value1) {
            sum = sum + parseFloat(value1.valuesvalue)
          })
      })
      this.cartData[id].pprice = String(this.cartData[id].qty * sum)
    }else{
      if(this.cartData[id].qty == 1){
        this.cartData.splice(id,1)
      }else{
        this.cartData[id].qty -= 1 
      var sum = 0
        this.cartData[id].addOn.forEach(function(value) {
          value.value.forEach(function(value1) {
            sum = sum + parseFloat(value1.valuesvalue)
          })
      })
      this.cartData[id].pprice = String(this.cartData[id].qty * sum)
      }
    }
    //console.log(this.cartData[id])
    //this.cartData.splice(id,1)
    this.finalCartTotal = this.getCartTotal()
  }

  //-----------Add to Cart-----------//
  addToCart() {
    sessionStorage.setItem('finalCartData',JSON.stringify(this.cartData))
    sessionStorage.setItem('finalCartTotal',String(this.finalCartTotal))
    sessionStorage.setItem('restId',this.id)
    this.router.navigateByUrl('/checkout')
  }

}
