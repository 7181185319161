import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css']
})
export class VerifyOtpComponent implements OnInit {

  otp:string 
  isOtpError = false
  customerEmail = ''

  constructor(private userauth:UserauthService, private toast: ToastrService, private userdata: UserdataService,private router: Router) { }

  ngOnInit(): void {
    if(this.userdata.getVerifyEmail() == '' || this.userdata.getVerifyEmail() == null || this.userdata.getVerifyEmail() == 'undefined'){
      this.router.navigateByUrl('/login')
    }
    this.customerEmail = this.userdata.getVerifyEmail()
  }

  onOtpChange(otp) {
    this.otp = otp;
    console.log(this.customerEmail)
    this.userauth.verifyEmailOtp({'registerotp':otp,'customer_email':this.customerEmail}).subscribe(
      (res:any) =>{
        this.toast.success('OTP Verified Successfully!','Success')
        this.userdata.removeVerifyEmail()
        this.router.navigateByUrl('/login')
      },err=>{
        this.isOtpError = true
      }
    )
  }

}
