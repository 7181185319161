import { Component,AfterViewInit,OnDestroy,ViewChild,ElementRef,ChangeDetectorRef, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators,NgForm } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { NgxSpinnerService } from 'ngx-spinner';
import { CheckoutService } from '../shared/checkout/checkout.service';
import { RestaurantService } from '../shared/restaurant/restaurant.service';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';
import { AngularStripeService } from '@fireflysemantics/angular-stripe-service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {

  @ViewChild('cardInfo', { static: false }) cardInfo: ElementRef;

  addressList = []
  restData: any = ""
  restLat = ""
  restLong = ""
  noAddress = true
  defaultAddress = ''
  imageURL = ''
  deliveryCharge: number = 0
  subTotal: number = 0
  productWithTaxDiscount: number = 0
  tip: number = 0
  tax: number = 0
  taxAmount: number = 0
  grandTotal: number = 0
  discount: number = 0
  cartData: any = ""
  isDelivery = true
  isCod = true

  orderForm = new FormGroup({
    customerId: new FormControl('', [Validators.required]),
    products: new FormControl('', [Validators.required]),
    addressId: new FormControl('', [Validators.required]),
    restId: new FormControl('', [Validators.required]),
    is_coupon: new FormControl(false),
    coupons_code: new FormControl(''),
    sub_total: new FormControl('', [Validators.required]),
    tax_amount: new FormControl('', [Validators.required]),
    coupon_discount: new FormControl('', [Validators.required]),
    after_discount: new FormControl(''),
    del_charges: new FormControl('', [Validators.required]),
    tip_amount: new FormControl('', [Validators.required, Validators.pattern('[0-9]{1,}')]),
    driver_instructions: new FormControl(''),
    order_instructions: new FormControl(''),
    payment_status: new FormControl(true),
    payment_remarks: new FormControl(''),
    txnId: new FormControl('0', [Validators.required]),
    payment_type: new FormControl('1', [Validators.required]),
    distance: new FormControl('1', [Validators.required]),
    is_order_contact_less: new FormControl(false),
    is_rest_coupon: new FormControl(false),
    is_customer_coupon: new FormControl(false),
    is_donation: new FormControl(false),
    order_takeaway: new FormControl(false),
    is_bell_to_ring: new FormControl(true),
    order_via: new FormControl('web'),
    is_cod: new FormControl(false),
    is_online: new FormControl(true)
  })

  stripe;
  loading = false;
  confirmation;

  card: any;
  cardHandler = this.onChange.bind(this);
  error: string;

  constructor(private router:Router ,private toast:ToastrService,private restaurant: RestaurantService, private userdata: UserdataService, private userauth:UserauthService, private checkout:CheckoutService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private spinner:NgxSpinnerService,private cd: ChangeDetectorRef, private stripeService:AngularStripeService) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.getMyAddress()
    this.calculate()
    this.subTotal = Number(this.userdata.getCartTotal())
    this.productWithTaxDiscount = Number(this.userdata.getCartTotal())
    this.orderForm.patchValue({ 'restId': this.userdata.getRestaurantId() })
    this.orderForm.patchValue({ 'customerId': this.userdata.getId() })
    this.orderForm.patchValue({ 'products': this.userdata.getCartData() })
    this.orderForm.patchValue({ 'sub_total': this.subTotal })
    this.orderForm.patchValue({ 'tax_amount': this.tax })
    this.orderForm.patchValue({ 'coupon_discount': this.discount })
    this.orderForm.patchValue({ 'after_discount': this.productWithTaxDiscount })
    this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
    this.orderForm.patchValue({ 'tip_amount': this.tip })
    this.cartData = JSON.parse(this.userdata.getCartData())
  }

  changeSelectedAddress(id,lat,long){
    $(".btn-address").removeClass("btn-success")
    $(".btn-address").addClass("btn-secondary")
    $("#"+id).removeClass("btn-secondary")
    $("#"+id).addClass("btn-success")
    this.selectAddress('event',id,lat,long)
  }
  
  getMyAddress() {
    this.spinner.show()
    var dropLat;
    var dropLong;
    this.restaurant.singleRestaurant({ '_id': this.userdata.getRestaurantId() }).subscribe(
      (res: any) => {
        this.restData = res.data
        this.restLat = res.data.rest_lat
        this.restLong = res.data.rest_long
        this.userauth.myAddress({ 'customerId': this.userdata.getId() }).subscribe(
          (res: any) => {
            this.addressList = res.data
            this.defaultAddress = res.defaultaddressId
            if (res.defaultaddressId != '' && res.defaultaddressId != undefined) {
              this.addressList.forEach(element => {
                if (element._id == this.defaultAddress) {
                  dropLat = element.customer_lat
                  dropLong = element.customer_long
                  this.noAddress = false
                }
              });
              this.orderForm.patchValue({ 'addressId': this.defaultAddress })
              this.checkout.calculateDistance({ 'originlat': this.restLat, 'originlong': this.restLong, 'destinationlat': dropLat, 'destinationlong': dropLong }).subscribe(
                (res: any) => {
                  //console.log(res)
                  this.orderForm.patchValue({ distance: res.distance })
                  this.deliveryCharge = Number(res.totalprice)                  
                  this.taxAmount = Number(res.taxamount)
                  this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
                  this.calculate()
                  this.spinner.hide()
                },
                err => {
                  //console.log(err)
                  this.toast.error(err.error.message,'Error')
                  this.orderForm.patchValue({ distance: err.error.distance })
                  this.deliveryCharge = Number(err.error.totalprice)
                  this.taxAmount = Number(err.error.taxamount)
                  this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
                  this.calculate()
                  this.spinner.hide()
                }
              )
            }
          },
          err => {
            this.spinner.hide()
            //this.toast.error('Address Cannot be loaded', 'Error')
          }
        )
      },
      err => {
        this.spinner.hide()
        //this.toast.error('Unable to fetch Restaurant Details', 'Error')
        //console.log(err)
      }
    )
  }

  
  calculate() {
    //this.tax = Number((this.taxAmount / 100) * this.subTotal)
    this.tax = Number((5 / 100) * this.subTotal)
    this.orderForm.patchValue({ 'tax_amount': this.tax })
    var product_with_tax = (Number(this.subTotal) + this.tax) - Number(this.discount)
    if (product_with_tax <= 0) {
      product_with_tax = 0
    }
    this.productWithTaxDiscount = product_with_tax
    var total = Number(product_with_tax) + Number(this.deliveryCharge) + Number(this.tip)
    this.grandTotal = total
    this.orderForm.patchValue({'after_discount':this.productWithTaxDiscount})
  }

  public getSanitizerUrl(photoname) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  //----------Get Cart Total------------//
  getCartTotal(){
    var total = 0
    this.cartData.forEach(function(element) {
      total = Number(total) + Number(element.pprice)
    })
    return total
  }

  //------Remove data from my orders------//
  removeDataOrder(val,id){
    if(val == 1){
      this.cartData[id].qty += 1 
      var sum = 0
        this.cartData[id].addOn.forEach(function(value) {
          value.value.forEach(function(value1) {
            sum = sum + parseFloat(value1.valuesvalue)
          })
      })
      this.cartData[id].pprice = String(this.cartData[id].qty * sum)
    }else{
      if(this.cartData[id].qty == 1){
        this.cartData.splice(id,1)
      }else{
        this.cartData[id].qty -= 1 
      var sum = 0
        this.cartData[id].addOn.forEach(function(value) {
          value.value.forEach(function(value1) {
            sum = sum + parseFloat(value1.valuesvalue)
          })
      })
      this.cartData[id].pprice = String(this.cartData[id].qty * sum)
      }
    }
    //console.log(this.cartData[id])
    //this.cartData.splice(id,1)
    this.subTotal = this.getCartTotal()
    console.log(this.subTotal)
    this.calculate()
  }

  isDeliveryOrTakeway(event) {
    if (event == true) {
      this.isDelivery = false
      this.orderForm.patchValue({ 'order_takeaway': true })
      this.orderForm.patchValue({ 'is_order_contact_less': false })
      //event.target.checked = true
      this.deliveryCharge = 0
      this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
      this.orderForm.patchValue({ 'tip_amount': 0 })
      //this.addTip()
      this.tip = this.orderForm.get('tip_amount').value
      this.calculate()
    } else {
      this.isDelivery = true
      this.orderForm.patchValue({ 'order_takeaway': false })
      //event.target.checked = true
      this.userauth.singleAddress({ 'customerId': this.userdata.getId(), '_id': this.orderForm.get('addressId').value }).subscribe(
        (res: any) => {
          this.selectAddress('event', res.data._id, res.data.customer_lat, res.data.customer_long)
        }, err => {
          this.deliveryCharge = 0
          this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
          this.noAddress = true
        }
      )
    }
  }

  selectAddress(event, id, lat, long) {
    var dropLat = lat
    var dropLong = long
    this.orderForm.patchValue({ 'addressId': id })
    this.noAddress = false
    this.checkout.calculateDistance({ 'originlat': this.restLat, 'originlong': this.restLong, 'destinationlat': dropLat, 'destinationlong': dropLong }).subscribe(
      (res: any) => {
        this.orderForm.patchValue({ distance: res.distance })
        //console.log(res)
        this.deliveryCharge = Number(res.totalprice)
        this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
        this.calculate()
      },
      err => {
        //this.toast.error(err.error.message,'Error')
        //console.log(err.error.totalprice)
        this.orderForm.patchValue({ distance: err.error.distance })
        this.deliveryCharge = Number(err.error.totalprice)
        this.orderForm.patchValue({ 'del_charges': this.deliveryCharge })
        this.calculate()
      }
    )
  }

  addTip() {
    var regexp = /^\d+(\.\d{1,2})?$/;
    // returns true
    if(regexp.test(this.orderForm.get('tip_amount').value)){
      this.tip = this.orderForm.get('tip_amount').value
      this.calculate()
      this.toast.success('Thank You For Tip','Success',{positionClass:'toast-top-center'})
    }else{
      this.toast.error('Please Enter Only Numbers','Error',{positionClass:'toast-top-center'})
    }
    //this.tip = this.orderForm.get('tip_amount').value
    //this.calculate()
  }

  isContactLess(event) {
    if (event.target.checked == true) {
      this.isCod = false
      this.orderForm.patchValue({ 'is_order_contact_less': true })
    }
    else {
      this.isCod = true
      this.orderForm.patchValue({ 'is_order_contact_less': false })
    }
  }

  isBellToRing(event) {
    if (event.target.checked == true) {
      this.orderForm.patchValue({ 'is_bell_to_ring': false })
    }
    else {
      this.orderForm.patchValue({ 'is_bell_to_ring': true })
    }
  }

  //Stripee
  ngAfterViewInit() {
    this.stripeService.setPublishableKey('pk_test_51Hp48qFPyhppKVhzjLTWZQgC9G3pp5IF4RM4Akh4CG9uTE2w1AkBHLtMSZ8wPWHlzHP5U3RAnQrCDuHquUyMCL0C00R9ddVAKH').then(
      stripe=> {
        this.stripe = stripe;
    const elements = stripe.elements();    
    this.card = elements.create('card');
    this.card.mount(this.cardInfo.nativeElement);
    this.card.addEventListener('change', this.cardHandler);
    });
  }

  ngOnDestroy() {
    this.card.removeEventListener('change', this.cardHandler);
    this.card.destroy();
  }

  onChange({ error }) {
    if (error) {
      this.error = error.message;
    } else {
      this.error = null;
    }
    this.cd.detectChanges();
  }

  async onSubmit(form: NgForm) {
    this.spinner.show();
    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      this.spinner.hide();
      Swal.fire('Oops!', 'Payment Declined. Please change card or payment method', 'error')
    } else {
      this.checkout.checkRestaurantOpen({'restId':this.orderForm.get('restId').value}).subscribe(
        (res: any) => {
          if (res.allow == true) {
            this.orderForm.patchValue({ 'txnId': token.id })
            this.orderForm.patchValue({ 'payment_status': true })
            this.orderForm.patchValue({ 'is_online': true })
            this.orderForm.patchValue({ 'is_cod': false })
            if (this.grandTotal <= 0) {
              this.checkout.placeOrder(this.orderForm.value).subscribe(
                (res: any) => {
                  this.spinner.hide();
                  this.toast.success(res.message, 'Success')
                  this.userdata.emptyCart()
                  this.router.navigateByUrl('/orderPlaced')
                },
                err => {
                  this.spinner.hide();
                  this.toast.error('Oops!! Unable to place order.', 'Error')
                  // console.log(err)
                }
              )
            }
            else {
              this.checkout.placeOnlineOrder({ 's_token': token.id, 'amount': this.grandTotal }).subscribe(
                (res: any) => {
                  console.log(res)
                  if (!res.success) {
                    this.orderForm.patchValue({ 'payment_status': false })
                    this.checkout.placeOrder(this.orderForm.value).subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        //  console.log(res,'rejection')
                      },
                      err => {
                        this.spinner.hide();
                        //this.toast.error('Oops!! Unable to place order.','Error')
                        console.log(err)
                      }
                    )
                    Swal.fire('Oopssss!', 'Payment Declined. Please change card or payment method', 'error')
                  }
                  if (res.success) {
                    //this.placeOrder()
                    this.checkout.placeOrder(this.orderForm.value).subscribe(
                      (res: any) => {
                        this.spinner.hide();
                        this.toast.success(res.message, 'Success')
                        this.userdata.emptyCart()
                        this.router.navigateByUrl('/orderPlaced')
                      },
                      err => {
                        this.spinner.hide();
                        this.toast.error('Oops!! Unable to place order.', 'Error')
                        // console.log(err)
                      }
                    )
                  }
                },
                err => {
                  this.orderForm.patchValue({ 'payment_status': false })
                  this.checkout.placeOrder(this.orderForm.value).subscribe(
                    (res: any) => {
                      this.spinner.hide();
                    },
                    err => {
                      this.spinner.hide();
                      this.toast.error('Oops!! Unable to place order.', 'Error')
                      //console.log(err)
                    }
                  )
                  Swal.fire('Oops!', 'Payment Declined. Please change card or payment method', 'error')
                }
              )
            }
          } else {
            this.spinner.hide()
            Swal.fire('Order Not Placed', 'Currently the restaurant is not accepting orders. Try any other restaurant', 'error')
          }
        }, err => {
          this.spinner.hide()
          Swal.fire('Order Not Placed', 'Server Issue. Try after some time', 'error')
        }
      )
    }
  }

  //------------Place COD Order
  placeOrder(){
    var data = {
      'customerId' : this.orderForm.get('customerId').value,
      'products' : this.orderForm.get('products').value,
      'addressId' : this.orderForm.get('addressId').value,
      'restId' : this.orderForm.get('restId').value,
      'is_coupon' : this.orderForm.get('is_coupon').value,
      'coupons_code' : this.orderForm.get('coupons_code').value,
      'sub_total' : this.orderForm.get('sub_total').value,
      'tax_amount' : this.orderForm.get('tax_amount').value,
      'coupon_discount' : this.orderForm.get('coupon_discount').value,
      'after_discount' : this.orderForm.get('after_discount').value,
      'del_charges' : this.orderForm.get('del_charges').value,
      'tip_amount' : this.orderForm.get('tip_amount').value,
      'driver_instructions' : this.orderForm.get('driver_instructions').value,
      'order_instructions' : this.orderForm.get('order_instructions').value,
      'payment_status' : this.orderForm.get('payment_status').value,
      'payment_remarks' : this.orderForm.get('payment_remarks').value,
      'txnId' : this.orderForm.get('txnId').value,
      'payment_type' : this.orderForm.get('payment_type').value,
      'distance' : this.orderForm.get('distance').value,
      'is_order_contact_less' : this.orderForm.get('is_order_contact_less').value,
      'is_rest_coupon' : this.orderForm.get('is_rest_coupon').value,
      'is_customer_coupon' : this.orderForm.get('is_customer_coupon').value,
      'is_donation' : this.orderForm.get('is_donation').value,
      'order_takeaway' : this.orderForm.get('order_takeaway').value,
      'is_bell_to_ring' : this.orderForm.get('is_bell_to_ring').value,
      'order_via' : this.orderForm.get('order_via').value,
      'is_cod' : this.orderForm.get('is_cod').value,
      'is_online' : this.orderForm.get('is_online').value,
    }
    this.checkout.checkRestaurantOpen({ 'restId': this.orderForm.get('restId').value }).subscribe(
      (res: any) => {
        if (res.allow == true) {
          this.orderForm.patchValue({ 'is_online': false })
          this.orderForm.patchValue({ 'is_cod': true })
          this.orderForm.patchValue({ 'payment_type': 2 })
          this.orderForm.patchValue({ 'payment_status': true })
          this.spinner.show();

          this.checkout.placeOrder(this.orderForm.value).subscribe(
            (res: any) => {
              this.spinner.hide();
              this.toast.success(res.message, 'Success')
              this.userdata.emptyCart()
              this.router.navigateByUrl('/orderPlaced')
            },
            err => {
              this.spinner.hide();
              this.toast.error('Oops!! Unable to place order.', 'Error')
              //  console.log(err)
            }
          )
        } else {
          Swal.fire('Order Not Placed', 'Currently the restaurant is not accepting orders. Try any other restaurant', 'error')
        }
      }, err => {
        Swal.fire('Order Not Placed', 'Server Issue. Try after some time', 'error')
      }
    )
  }

  //-----Show Coupon Applied-----//
  couponDetails = {
    offer_code: '', discount: 0
  }
  couponApplied = false
  //-----Applu coupon -------//
  applyCoupon() {
    if(this.orderForm.get('coupons_code').value == ''){
      Swal.fire('Oops!','Enter Coupon Code to get discount','error')
      return
    }
    this.spinner.show()
    var val = this.orderForm.get('coupons_code').value
    this.checkout.checkCoupon({ 'offers_code': val }).subscribe(
      (res: any) => {
        var data = res.data
        if (res.success == false) {
          this.spinner.hide()
          Swal.fire('Oops!', res.message, 'error')
          return
        }
        if (data.minimum_order > this.subTotal) {
          this.orderForm.patchValue({ 'is_coupon': false })
          this.orderForm.patchValue({ 'coupons_code': '' })
          this.orderForm.patchValue({ 'coupon_discount': 0 })
          this.discount = 0
          this.calculate()
          this.spinner.hide()
          Swal.fire('Coupon Not Valid', 'Minimum Order Amount should be $'+data.minimum_order+' to apply this coupon.', 'error')
          return
        }
        else {
          if (data.offer_on == 2) {
            var catid = data.categoryId
            var array = JSON.parse(this.userdata.getCartData())
            array.forEach(element => {
              if (element.catid !== catid) {
                this.orderForm.patchValue({ 'is_coupon': false })
                this.orderForm.patchValue({ 'coupons_code': '' })
                this.orderForm.patchValue({ 'coupon_discount': 0 })
                this.discount = 0
                this.spinner.hide()
                Swal.fire('Oops', 'Coupon not applicable on selected category', 'error')
                return
              }
            });
          }
          if (data.offer_on == 3) {
            var pid = data.productId
            var array = JSON.parse(this.userdata.getCartData())
            array.forEach(element => {
              if (element.pid !== pid) {
                this.orderForm.patchValue({ 'is_coupon': false })
                this.orderForm.patchValue({ 'coupons_code': '' })
                this.orderForm.patchValue({ 'coupon_discount': 0 })
                this.discount = 0
                this.spinner.hide()
                Swal.fire('Oops', 'Coupon not applicable on selected products', 'error')
                return
              }
            });
          }
          if (data.offer_on == 4) {
            if (data.restId != this.userdata.getRestaurantId()) {
              this.orderForm.patchValue({ 'is_coupon': false })
              this.orderForm.patchValue({ 'coupons_code': '' })
              this.orderForm.patchValue({ 'coupon_discount': 0 })
              this.discount = 0
              this.spinner.hide()
              Swal.fire('Oops', 'Coupon Not Valid For This Restaurant', 'error')
              return
            }
          }
        }
        if (data.offers_type == 1) {
          if (data.customerId != null) {
            this.orderForm.patchValue({ 'is_customer_coupon': true })
          }
          this.discount = Number(data.offer_amount)
        }
        else {
          var percent = this.subTotal * (data.offer_amount / 100)
          if (data.maximum_discount == 0 || data.maximum_discount == null || data.maximum_discount == "") {
            this.discount = percent
          }
          else {
            if (data.maximum_discount < percent) {
              this.discount = data.maximum_discount
            }
            else {
              this.discount = percent
            }
          }
        }
        if (data.offer_on == 4) {
          this.orderForm.patchValue({ 'is_rest_coupon': true })
        }
        this.orderForm.patchValue({ 'is_coupon': true })
        this.orderForm.patchValue({ 'coupons_code': data.offers_code })
        this.orderForm.patchValue({ 'coupon_discount': this.discount })
        this.calculate()
        this.couponDetails.offer_code = data.offers_code
        this.couponDetails.discount = this.discount
        this.couponApplied = true
        this.spinner.hide()
        Swal.fire(data.offers_code, 'Offer Applied Successfully', 'success')
      },
      err => {
        this.spinner.hide()
        this.toast.error(err.message, 'Error')
        //console.log(err)
      }
    )
    //console.log(data)

  }

  //------Remove Discount---------//
  removeDiscount() {
    this.orderForm.patchValue({ 'is_rest_coupon': false })
    this.orderForm.patchValue({ 'is_coupon': false })
    this.orderForm.patchValue({ 'coupons_code': '' })
    this.orderForm.patchValue({ 'coupon_discount': 0 })
    this.discount = 0
    this.couponApplied = false
    this.calculate()
  }

}
