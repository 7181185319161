import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.css']
})
export class LoginPageComponent implements OnInit {

  formGroup = new FormGroup({
    'customer_email'       : new FormControl('',[Validators.required]),
    'customer_password'    : new FormControl('',[Validators.required]),
  })

  returnUrl: string;
  showError = false

  constructor(private route: ActivatedRoute,private userauth: UserauthService, private toast: ToastrService, private userdata: UserdataService, private router: Router,private afAuth: AngularFireAuth,private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  get f(){
    return this.formGroup.controls
  }

  onSubmit(){
    this.userauth.login(this.formGroup.value).subscribe(
      (res: any) => {
        //console.log(res)
        if(res.success){
          this.toast.success('Login Successfully','Success')
          this.userdata.setData(res)
          //this.router.navigateByUrl('/home')
          // login successful so redirect to return url
          this.router.navigateByUrl(this.returnUrl);
          this.showError = false
          this.userdata.removeVerifyEmail()
        }
        else{
          //this.toast.error(res.message,'Error')
          Swal.fire('Oops',res.message,'error')
          this.showError = true
          if(!res.is_otp_verified){
            this.userdata.setVerifyEmail(res.data.customer_email)
            this.router.navigateByUrl('/verify')
          }
        }
        //console.log(res)
      },
      err => {
        //this.toast.error('Wrong username and password','Error')
        Swal.fire('Oops',err.error.message,'error')
        //console.log(err)
        this.showError = false
        //console.log(err)
      } 
    )
  }

  //----------------------------------Social Login---------------------------------//
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }  

  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }  

  // Auth logic to run auth providers
   AuthLogin(provider) {
     
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
      this.userauth.login({'customer_email':result.user.email,'customer_password':result.user.uid}).subscribe(
        (res: any) => {
          this.spinner.show()
          if(res.success){
            this.toast.success('Login Successfully','Success')
            Swal.fire({
              title : 'Success',
              text : 'Login Successful',
              icon : 'success'
            }).then((res)=>{
              this.router.navigateByUrl(this.returnUrl);
            })
            this.userdata.setData(res)
            this.showError = false
            this.userdata.removeVerifyEmail()            
            this.spinner.hide()
          }
          else{
            //this.toast.error(res.message,'Error')
            Swal.fire('Oops',res.message,'error')
            this.showError = true
            if(!res.is_otp_verified){
              this.userdata.setVerifyEmail(res.data.customer_email)
              this.router.navigateByUrl('/verify')
              this.spinner.hide()
            }
          }
        },
        err => {
          //this.toast.error('Wrong username and password','Error')  
          Swal.fire('Oops',err.error.message,'error')
          //console.log(err)
          this.showError = false
          //console.log(err)

        } 
      )
    }).catch((error) => {
      console.log(error)
    })
  }
}
