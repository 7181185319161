<section class="section pt-5 pb-5 text-center bg-white">
    <div class="container">
        <div class="row">
            <div class="col-sm-12">
                <h5 class="m-0">Operate food store or restaurants? <a [routerLink]="['/register/restaurant']">Work With Us</a></h5>
            </div>
        </div>
    </div>
</section>
<section class="footer pt-5 pb-5">
    <div class="container">
        <div class="row">
            <div class="col-md-4 col-12 col-sm-12">
                <h6 class="mb-3">Subscribe to our Newsletter</h6>
                <form class="newsletter-form mb-1">
                    <div class="input-group">
                        <input type="text" placeholder="Please enter your email" class="form-control">
                        <div class="input-group-append">
                            <button type="button" class="btn btn-primary">
                                Subscribe
                            </button>
                        </div>
                    </div>
                </form>
                <p><a class="text-info" [routerLink]="['/register']">Register now</a> to get updates on <a
                        href="javascript:void(0)">Offers and Coupons</a></p>
                <div class="app">
                    <p class="mb-2">DOWNLOAD APP</p>
                    <a href="javascript:void(0)">
                        <img class="img-fluid" src="../../assets/img/google.png">
                    </a>
                    <a href="javascript:void(0)">
                        <img class="img-fluid" src="../../assets/img/apple.png">
                    </a>
                </div>
            </div>
            <div class="col-md-1 col-sm-6 mobile-none">
            </div>
            <div class="col-md-2 col-6 col-sm-4">
                <h6 class="mb-3">About VegFood</h6>
                <ul>
                    <li><a href="javascript:void(0)">About Us</a></li>
                    <li><a href="javascript:void(0)">Culture</a></li>
                    <li><a href="javascript:void(0)">Blog</a></li>
                    <li><a href="javascript:void(0)">Careers</a></li>
                    <li><a href="javascript:void(0)">Contact</a></li>
                </ul>
            </div>
            <div class="col-md-2 col-6 col-sm-4">
                <h6 class="mb-3">For Foodies</h6>
                <ul>
                    <li><a href="javascript:void(0)">Community</a></li>
                    <li><a href="javascript:void(0)">Developers</a></li>
                    <li><a href="javascript:void(0)">Blogger Help</a></li>
                    <li><a href="javascript:void(0)">Verified Users</a></li>
                    <li><a href="javascript:void(0)">Code of Conduct</a></li>
                </ul>
            </div>
            <div class="col-md-2 m-none col-4 col-sm-4">
                <h6 class="mb-3">For Restaurants</h6>
                <ul>
                    <li><a href="javascript:void(0)">Advertise</a></li>
                    <li><a href="javascript:void(0)">Add a Restaurant</a></li>
                    <li><a href="javascript:void(0)">Claim your Listing</a></li>
                    <li><a href="javascript:void(0)">For Businesses</a></li>
                    <li><a href="javascript:void(0)">Owner Guidelines</a></li>
                </ul>
            </div>
        </div>
    </div>
</section>
<section class="footer-bottom-search pt-5 pb-5 bg-white">
    <div class="container">
        <div class="row">
            <div class="col-xl-12">
                <!-- <p class="text-black">POPULAR COUNTRIES</p>
                <div class="search-links">
                    <a href="javascript:void(0)">Australia</a> | <a href="javascript:void(0)">Brasil</a> | <a href="javascript:void(0)">Canada</a> | <a
                        href="javascript:void(0)">Chile</a> | <a href="javascript:void(0)">Czech Republic</a> | <a href="javascript:void(0)">India</a> | <a
                        href="javascript:void(0)">Indonesia</a> | <a href="javascript:void(0)">Ireland</a> | <a href="javascript:void(0)">New Zealand</a> | <a
                        href="javascript:void(0)">United Kingdom</a> | <a href="javascript:void(0)">Turkey</a> | <a href="javascript:void(0)">Philippines</a> | <a
                        href="javascript:void(0)">Sri Lanka</a> | <a href="javascript:void(0)">Australia</a> | <a href="javascript:void(0)">Brasil</a> | <a
                        href="javascript:void(0)">Canada</a> | <a href="javascript:void(0)">Chile</a> | <a href="javascript:void(0)">Czech Republic</a> | <a
                        href="javascript:void(0)">India</a> | <a href="javascript:void(0)">Indonesia</a> | <a href="javascript:void(0)">Ireland</a> | <a href="javascript:void(0)">New
                        Zealand</a> | <a href="javascript:void(0)">United Kingdom</a> | <a href="javascript:void(0)">Turkey</a> | <a
                        href="javascript:void(0)">Philippines</a> | <a href="javascript:void(0)">Sri Lanka</a><a href="javascript:void(0)">Australia</a> | <a
                        href="javascript:void(0)">Brasil</a> | <a href="javascript:void(0)">Canada</a> | <a href="javascript:void(0)">Chile</a> | <a href="javascript:void(0)">Czech
                        Republic</a> | <a href="javascript:void(0)">India</a> | <a href="javascript:void(0)">Indonesia</a> | <a href="javascript:void(0)">Ireland</a> |
                    <a href="javascript:void(0)">New Zealand</a> | <a href="javascript:void(0)">United Kingdom</a> | <a href="javascript:void(0)">Turkey</a> | <a
                        href="javascript:void(0)">Philippines</a> | <a href="javascript:void(0)">Sri Lanka</a> | <a href="javascript:void(0)">Australia</a> | <a
                        href="javascript:void(0)">Brasil</a> | <a href="javascript:void(0)">Canada</a> | <a href="javascript:void(0)">Chile</a> | <a href="javascript:void(0)">Czech
                        Republic</a> | <a href="javascript:void(0)">India</a> | <a href="javascript:void(0)">Indonesia</a> | <a href="javascript:void(0)">Ireland</a> |
                    <a href="javascript:void(0)">New Zealand</a> | <a href="javascript:void(0)">United Kingdom</a> | <a href="javascript:void(0)">Turkey</a> | <a
                        href="javascript:void(0)">Philippines</a> | <a href="javascript:void(0)">Sri Lanka</a>
                </div> -->
                <p class="mt-4 text-black">POPULAR FOOD</p>
                <div class="search-links">
                    <span *ngFor="let cat of globalCatList">
                        <a href="javascript:void(0)" class="text-uppercase">{{cat.category_name}}</a> | 
                    </span>
                </div>
            </div>
        </div>
    </div>
</section>
<footer class="pt-4 pb-4 text-center">
    <div class="container">
        <p class="mt-0 mb-0">© Copyright 2021 Veggie Lyfe. All Rights Reserved</p>
        <small class="mt-0 mb-0"> Made with <i class="fas fa-heart heart-icon text-danger"></i>
        </small>
    </div>
</footer>