import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../shared/order/order.service';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-add-support',
  templateUrl: './add-support.component.html',
  styleUrls: ['./add-support.component.css']
})
export class AddSupportComponent implements OnInit {

  id =''
  orderId:any = ''
  imageError: string
  isCOD = false
  issueList = []
  imageURL = ''

  formGroup = new FormGroup({
    'customerId'        : new FormControl('',[Validators.required]),
    'orderId'           : new FormControl('',[Validators.required]),
    'issue_name'        : new FormArray([],[Validators.required]),
    'issue_description' : new FormControl('',[Validators.required]),
    'refund_type'       : new FormControl('',[Validators.required]),
    'restId'            : new FormControl('',[Validators.required]),
    'orderissue_image'  : new FormControl('')
  })
  
  constructor(private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl,private spinner:NgxSpinnerService,private userauth:UserauthService,private order:OrderService, private router:Router, private activate:ActivatedRoute,private userdata: UserdataService,private toast: ToastrService) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.formGroup.patchValue({'orderId':this.id})
    this.formGroup.patchValue({'customerId':this.userdata.getId()})
    this.formGroup.patchValue({'refund_type':2})
    this.getOrderDetail()
    this.getCustomizations()
    this.getSupportForOrder()
  }

  get f(){
    return this.formGroup.controls
  }

  onCheckboxChange(e) {
    const issue_name: FormArray = this.formGroup.get('issue_name') as FormArray;
    if (e.target.checked) {
      issue_name.push(new FormControl(e.target.value));
    } else {
      const index = issue_name.controls.findIndex(x => x.value === e.target.value);
      issue_name.removeAt(index);
    }
  }

  getCustomizations(){
    this.userauth.getCustomizations({'customization_type':8}).subscribe(
      (res:any) =>{
        this.issueList = res.result.other_points
      },err =>{
        console.log(err)
      }
    )
  }

  getOrderDetail(){
    this.order.singleOrder({'_id':this.id,'customerId':this.userdata.getId()}).subscribe(
      (res:any) =>{
        this.formGroup.patchValue({'restId':res.data.restId._id})
        this.orderId = res.data.orderId
        this.isCOD = res.data.is_cod
      },err =>{
        console.log(err)
      }
    )
  }

  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        this.f.orderissue_image.errors.required ='error'
        return false
      }
      else{
        this.formGroup.patchValue({
          orderissue_image: evnt.target.files[0]
        })
      }      
    }
  }

  changeRefundType(event){
    if(event.target.value == 1){
      this.formGroup.patchValue({'refund_type':1})
    }else{
      this.formGroup.patchValue({'refund_type':2})
    }
  }

  onSubmit(){
    const data = new FormData()
    data.append("customerId",this.formGroup.get("customerId").value)
    data.append("orderId",this.formGroup.get("orderId").value)
    data.append("issue_name",this.formGroup.get("issue_name").value)
    data.append("issue_description",this.formGroup.get("issue_description").value)
    data.append("refund_type",this.formGroup.get("refund_type").value)
    data.append("restId",this.formGroup.get("restId").value)
    data.append("orderissue_image",this.formGroup.get("orderissue_image").value)
    this.order.issueSupport(data).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/account')
      },err=>{
        console.log(err)
      }
    )
  }

  //------------------------------------------------------------
  isSupportIssued = false
  issueData:any = ''
  getSupportForOrder(){
    this.spinner.show()
    this.order.supportForOrder({'orderId':this.id}).subscribe(
      (res:any) =>{
        if(res.success == true){
          this.isSupportIssued = true
          this.issueData = res.data
        }else{
          this.isSupportIssued = false
        }
        this.spinner.hide()
      },
      err =>{
        //this.isSupportIssued = false
        console.log(err.error.message)
        this.spinner.hide()
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 
}
