import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { OrderService } from '../shared/order/order.service';
import { UserdataService } from '../shared/userdata/userdata.service';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css'],
  providers: [DatePipe]
})
export class OrderDetailComponent implements OnInit {

  imageURL = ''
  orderDetails:any = ""
  productDetails:any = ""
  id = ''
  customer_name = ''
  
  constructor(private spinner:NgxSpinnerService,private userdata: UserdataService, private toast:ToastrService, private router:Router, private activate: ActivatedRoute, private order:OrderService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl,private datePipe: DatePipe) { 
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.id = this.activate.snapshot.paramMap.get('id')
    this.customer_name = this.userdata.getName()
    this.singleOrder()
  }

  singleOrder(){
    this.spinner.show()
    this.order.singleOrder({'customerId':this.userdata.getId(),'_id':this.id}).subscribe(
      (res:any) => {
        this.orderDetails = res.data
        this.productDetails = JSON.parse(res.data.products)
        console.log(this.orderDetails)
        this.spinner.hide()
      },
      err => {
        this.spinner.hide()
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  getJson(value){
    //console.log(value)
    return JSON.parse(value)
  }

  //Rating
  restRate = new FormControl(3, Validators.required);
  driverRate = new FormControl(3, Validators.required);
  systemRate = new FormControl(3, Validators.required);
  ratingData

  rateOrder(){
    this.ratingData = {
      'driver_rating':this.driverRate.value,
      'system_rating':this.systemRate.value,
      'rest_rating':this.restRate.value,
      'customerId':this.userdata.getId(),
      'orderId':this.activate.snapshot.paramMap.get('id')
    }
    this.order.rateOrder(this.ratingData).subscribe(
      (res:any) => {
        this.singleOrder()
        this.toast.success('Thank you for your Rating','Success')
        //console.log(res)
      },
      err => {
        //console.log(err)
        this.toast.error(err.error.message,'Error')
      }
    )
  }

}
