import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserauthService } from '../shared/userauth/userauth.service';

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css']
})
export class ForgetpasswordComponent implements OnInit {
  otp: string;
  isOtpSent = false
  isEmailError = false
  isOtpError = false
  isOtpVerify = false
  customerEmail = ''
  emailError = ''

  formGroup = new FormGroup({
    customer_email : new FormControl('',[Validators.required])
  })

  passwordGroup = new FormGroup({
    customer_email : new FormControl(''),
    customer_password : new FormControl('',[Validators.required,Validators.minLength(6)]),
    confirm_password : new FormControl('',[Validators.required])
  })

  constructor(private userauth:UserauthService, private toast:ToastrService, private router:Router) { }

  ngOnInit(): void {

  }

  get f(){
    return this.passwordGroup.controls
  }

  onOtpChange(otp) {
    this.otp = otp;
    this.userauth.checkForgetOtp({'otp':otp,'customer_email':this.customerEmail}).subscribe(
      (res:any) =>{
        this.toast.success('OTP Verified Successfully!','Success')
        this.isOtpError = false
        this.isOtpVerify = true
        //this.router.navigateByUrl('/changePassword')
      },err=>{
        this.isOtpError = true
        this.isOtpVerify = false
      }
    )
  }

  onSubmit(){
    this.userauth.forgetPassword(this.formGroup.value).subscribe(
      (res:any) =>{
        this.customerEmail = this.formGroup.get('customer_email').value
        this.isOtpSent = true
        this.isEmailError = false
        this.emailError = ''
      },err=>{
        this.isEmailError = true
        this.isOtpSent = false
        this.emailError = err.error.message
      }
    )
  }

  changePassword(){
    this.passwordGroup.patchValue({'customer_email':this.customerEmail})
    this.userauth.changeForgetPassword(this.passwordGroup.value).subscribe(
      (res:any) =>{
        this.toast.success(res.message,'Success')
        this.router.navigateByUrl('/login')
      },err =>{
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  passwordBoolean = false

  passwordWork()
  {
    this.passwordBoolean = false

    if(this.passwordGroup.get('customer_password').value == this.passwordGroup.get('confirm_password').value)
    {
      this.passwordBoolean = true
    }
  }

}
