<app-topbar-page></app-topbar-page>
<section class="section pt-5 pb-5 osahan-not-found-page">
    <div class="container">
        <div class="row">
            <div class="col-md-12 text-center pt-5 pb-5">
                <img class="img-fluid" src="../../assets/img/404.png" alt="404">
                <h1 class="mt-2 mb-2">Page not found</h1>
                <p>Uh-oh! Looks like the page you are trying to access, doesn't <br>exist. Please start afresh.</p>
                <a class="btn btn-primary btn-lg" [routerLink]="['/home']">GO HOME</a>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>