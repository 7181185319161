<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Support</h1>
    <h6 class="text-white-50">Best deals at your favourite restaurants</h6>
</section>
<section class="section pt-5 pb-5 products-listing">
    <div class="container">
        <div class="row">
            <div class="col-md-10 m-auto bg-white p-4" *ngIf="!isSupportIssued">
                <h6 class="mb-3 mt-0 mb-3">Raise Support Ticket</h6>
                <form [formGroup]="formGroup" (submit)="onSubmit()">
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="inputPassword4">Order-ID</label>
                            <div class="input-group">
                                <input type="text" class="form-control" [value]="this.orderId" [disabled]="true">
                            </div>
                            <small class="form-text"
                                *ngIf="f.orderId.invalid && (f.orderId.dirty || f.orderId.touched)">
                                <small *ngIf="f.orderId.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="inputPassword4">Issues</label><br />
                            <span *ngFor="let issue of issueList">
                                <input type="checkbox" [value]="issue.value" name="issue_name"
                                    (change)="onCheckboxChange($event)"> {{issue.value}} &nbsp;
                            </span>
                            <small class="form-text"
                                *ngIf="f.issue_name.invalid && (f.issue_name.dirty || f.issue_name.touched)">
                                <small *ngIf="f.issue_name.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="issue_description">Issue Description</label>
                            <textarea name="issue_description" id="issue_description" class="form-control" cols="30"
                                rows="10" placeholder="Description" formControlName="issue_description"></textarea>
                            <small class="form-text"
                                *ngIf="f.issue_description.invalid && (f.issue_description.dirty || f.issue_description.touched)">
                                <small *ngIf="f.issue_description.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="refund_type">Refund Type</label><br />
                            <input type="radio" (change)="changeRefundType($event)" value="2" name="refund_type"
                                checked> Coupon &nbsp;
                            <input type="radio" (change)="changeRefundType($event)" value="1" name="refund_type"
                                [disabled]="isCOD"> Refund
                            <small class="form-text"
                                *ngIf="f.refund_type.invalid && (f.refund_type.dirty || f.refund_type.touched)">
                                <small *ngIf="f.refund_type.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="orderissue_image">Image</label>
                            <input type="file" formControlName="orderissue_image" class="form-control" (change)="onFile($event)" name="orderissue_image">
                            <small class="form-text"
                                *ngIf="f.orderissue_image.invalid && (f.orderissue_image.dirty || f.orderissue_image.touched)">
                                <small *ngIf="f.orderissue_image.errors.required">Invalid Value</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12 mb-0">
                            <button type="submit" class="btn btn-success btn-block btn-lg"
                                [disabled]="formGroup.invalid">SUBMIT <i class="icofont-long-arrow-right"></i></button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-10 m-auto bg-white p-4" *ngIf="isSupportIssued">
                <h6 class="mb-3 mt-0 mb-3">Support Ticket</h6>
                <table class="table mt-3 mb-0">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-black font-weight-bold" scope="col">Issue</th>
                            <th class="text-right text-black font-weight-bold" scope="col">Description</th>
                            <th class="text-right text-black font-weight-bold" scope="col">Refund Suggestion</th>
                            <th class="text-right text-black font-weight-bold" scope="col">Status</th>
                            <th class="text-right text-black font-weight-bold" scope="col" *ngIf="issueData.orderissue_image != ''">Document</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{{issueData.issue_name}}</td>
                            <td class="text-right">{{issueData.issue_description}}</td>
                            <td class="text-right"><span *ngIf="issueData.refund_type == 1">Refund</span><span *ngIf="issueData.refund_type == 2">Coupon</span></td>
                            <td class="text-right"><span *ngIf="!issueData.is_issued_used" class="badge badge-warning">Pending</span>
                                <span class="badge badge-success" *ngIf="issueData.is_issued_used && issueData.is_issue_accepted">REFUNDED</span><span class="badge badge-warning" *ngIf="issueData.is_issued_used && !issueData.is_issue_accepted">REJECTED</span>
                            </td>
                            <td class="text-right" *ngIf="issueData.orderissue_image != ''">
                                <a [href]="getSanitizerUrl(issueData.orderissue_image)">View Document</a>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table class="table mt-3 mb-0" *ngIf="issueData.is_issued_used && issueData.is_issue_accepted">
                    <thead class="thead-dark">
                        <tr>
                            <th class="text-left text-black font-weight-bold" scope="col">Refund Type</th>
                            <th class="text-right text-black font-weight-bold" scope="col">Refund Details</th>
                            <th class="text-right text-black font-weight-bold" scope="col">Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="text-left"><span class="badge badge-primary" *ngIf="!issueData.is_coupon_refunded">Refund</span>
                                <span class="badge badge-info" *ngIf="issueData.is_coupon_refunded">Coupon</span></td>
                            <td class="text-right"><span *ngIf="!issueData.is_coupon_refunded">Transaction ID : {{issueData.txnId}}</span>  <span *ngIf="issueData.is_coupon_refunded">Coupon Code : {{issueData.coupon}}</span></td>
                            <td class="text-right">{{issueData.solution_text}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>