import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';  

@Injectable({
  providedIn: 'root'
})
export class SharedataService {

  private name = new BehaviorSubject(sessionStorage.getItem('userLogin') || 0 );

  getName = this.name.asObservable(); 

  constructor() {}

  setName(data) {
    sessionStorage.setItem('userLogin',data)
    this.name.next(data);
  }
}
