import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class UserauthService {

  BASEURI: string = ""
  Token = ''
  constructor(private http: HttpClient, @Inject('BASE_URL') _baseUrl:  string, private userdata: UserdataService) {
    this.BASEURI = _baseUrl
    this.Token = userdata.getToken()
    //console.log('auth '+this.userdata.getToken())
  }

  login(form){
    return this.http.post(this.BASEURI + '/login', form)
  }

  register(form){
    return this.http.post(this.BASEURI + '/register', form)
  }

  //-----------Forget Password---------------//
  forgetPassword(form){
    return this.http.post(this.BASEURI + '/forgetpassword', form)
  }

  //----------Check forget password otp---------//
  checkForgetOtp(form){
    return this.http.post(this.BASEURI + '/checkotp', form)
  }

  //----------Register email verification otp---------//
  verifyEmailOtp(form){
    return this.http.post(this.BASEURI + '/verifyemail ', form)
  }

  //----------Change forget password---------//
  changeForgetPassword(form){
    return this.http.post(this.BASEURI + '/forgetchangepassword', form)
  }

  public getProfile(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/mydetail",form, { headers: header_object})
  }

  public updateProfile(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/updateprofilecustomer",form, { headers: header_object})
  }

  public changePassword(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/changepasswordcustomer",form, { headers: header_object})
  }

  public myAddress(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/myaddress",form, { headers: header_object})
  }

  public addAddress(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/addaddress",form, { headers: header_object})
  }

  public singleAddress(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/fetchsingleaddress",form, { headers: header_object})
  }

  public updateAddress(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/updateaddress",form, { headers: header_object})
  }

  public setDefaultAddress(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/changemydefault",form, { headers: header_object})
  }

  //----------Get Customizations-------//
  public getCustomizations(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BASEURI + "/showcutomizationopen",form, { headers: header_object})
  }
}
