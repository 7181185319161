<ngx-spinner></ngx-spinner>
<div class="container-fluid">
    <div class="row no-gutter">
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div class="col-md-8 col-lg-6">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5">
                            <h3 class="login-heading mb-4">Welcome back!</h3>
                            <form [formGroup]="formGroup" (submit)="onSubmit()">
                                <div class="form-label-group">
                                    <input type="email" id="inputEmail" class="form-control"
                                        placeholder="Email address" formControlName="customer_email">
                                    <label for="inputEmail">Email address</label>
                                    <small class="form-text" *ngIf="f.customer_email.invalid && (f.customer_email.dirty || f.customer_email.touched)">
                                        <small *ngIf="f.customer_email.errors.required">Invalid Value</small>
                                    </small>
                                </div>
                                <div class="form-label-group">
                                    <input type="password" id="inputPassword" class="form-control" placeholder="Password" formControlName="customer_password">
                                    <label for="inputPassword">Password</label>
                                    <small class="form-text" *ngIf="f.customer_password.invalid && (f.customer_password.dirty || f.customer_password.touched)">
                                        <small *ngIf="f.customer_password.errors.required">Invalid Value</small>
                                    </small>
                                </div>
                                <!-- <div class="custom-control custom-checkbox mb-3">
                                    <input type="checkbox" class="custom-control-input" id="customCheck1">
                                    <label class="custom-control-label" for="customCheck1">Remember password</label>
                                </div> -->
                                <button type="submit" class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" [disabled]="!formGroup.valid">Sign In</button>
                                <div class="text-center pt-3">Don’t have an account? <a class="font-weight-bold" [routerLink]="['/register']">Sign Up</a>
                                </div>
                                <div class="text-center pt-3"><a class="font-weight-bold" [routerLink]="['/forgetPassword']">Forgot Password ?</a>
                                </div>
                            </form>
                            <hr class="my-4">
                            <p class="text-center">LOGIN WITH</p>
                            <div class="row">
                                <div class="col pr-2">
                                    <button
                                        class="btn pl-1 pr-1 btn-lg btn-google font-weight-normal text-white btn-block text-uppercase"
                                        type="button" (click)="GoogleAuth()"><i class="fab fa-google mr-2"></i> Google</button>
                                </div>
                                <div class="col pl-2">
                                    <button
                                        class="btn pl-1 pr-1 btn-lg btn-facebook font-weight-normal text-white btn-block text-uppercase"
                                        type="button" (click)="FacebookAuth()"><i class="fab fa-facebook-f mr-2"></i> Facebook</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>