<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="section pt-4 pb-4 osahan-account-page">
    <div class="container">
        <div class="row">
            <div class="col-md-3">
                <div class="osahan-account-page-left shadow-sm bg-white h-100">
                    <div class="border-bottom p-4">
                        <div class="osahan-user text-center">
                            <div class="osahan-user-media">
                                <img class="mb-3 rounded-pill shadow-sm mt-1" [src]="getSanitizerUrl(userData.customer_pic)">
                                <div class="osahan-user-media-body">
                                    <h6 class="mb-2">{{name}}</h6>
                                    <p class="mb-1">+1 {{userData.customer_phone}}</p>
                                    <p><a href="javascript:void(0)" class="__cf_email__">{{email}}</a>
                                    </p>
                                    <!-- <p class="mb-0 text-black font-weight-bold"><a class="text-primary mr-3" data-toggle="modal" data-target="#edit-profile-modal" href="javascript:void(0)"><i class="icofont-ui-edit"></i> EDIT</a></p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="nav nav-tabs flex-column border-0 pt-4 pl-4 pb-4" id="myTab" role="tablist">
                        <li class="nav-item">
                            <a class="nav-link active" id="orders-tab" data-toggle="tab" href="#orders" role="tab"
                                aria-controls="orders" aria-selected="true"><i class="icofont-food-cart"></i>
                                Orders</a>
                        </li>
                        <li class="nav-item" *ngIf="isSocial == 'false'">
                            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false"><i class="icofont-user-alt-5"></i> My Profile</a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="favourites-tab" data-toggle="tab" href="#favourites" role="tab"
                                aria-controls="favourites" aria-selected="false"><i class="icofont-heart"></i>
                                Favourites</a>
                        </li>
                        <!-- <li class="nav-item">
                            <a class="nav-link" id="payments-tab" data-toggle="tab" href="#payments" role="tab"
                                aria-controls="payments" aria-selected="false"><i class="icofont-credit-card"></i>
                                Payments</a>
                        </li> -->
                        <li class="nav-item">
                            <a class="nav-link" id="addresses-tab" data-toggle="tab" href="#addresses" role="tab"
                                aria-controls="addresses" aria-selected="false"><i class="icofont-location-pin"></i>
                                Addresses</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-md-9">
                <div class="osahan-account-page-right shadow-sm bg-white p-4 h-100">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="orders" role="tabpanel" aria-labelledby="orders-tab">
                            <h4 class="font-weight-bold mt-0 mb-4">Orders</h4>
                            <div class="col-md-12 col-sm-12 mb-4 pb-2 text-center" *ngIf="myOrderList.length == 0">
                                <h6 class="mb-1" class="text-danger">Order History is empty.</h6>
                            </div>
                            <div class="bg-white card mb-4 order-list shadow-sm" *ngFor="let order of myOrderList | slice: (pageOrder-1) * pageSize : pageOrder * pageSize; let i = index">
                                <div class="gold-members p-4">
                                    <a href="javascript:void(0)">
                                        <div class="media">
                                            <img class="mr-4" [src]="getSanitizerUrl(order.restId.logo)" alt="Generic placeholder image">
                                            <div class="media-body">
                                                <span class="float-right text-info" *ngIf="order.order_current_status == 101">Order Placed <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-danger" *ngIf="order.order_current_status == 102">Payment Declined <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-info" *ngIf="order.order_current_status == 103">Preparing <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-danger" *ngIf="order.order_current_status == 104 || order.order_current_status == 110">Not Accepted <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-primary" *ngIf="order.order_current_status == 105">Prepared <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-info" *ngIf="order.order_current_status == 106">Ready For Pickup <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-primary" *ngIf="order.order_current_status == 107">On-Way <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-success" *ngIf="order.order_current_status == 108">Delivered <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-danger" *ngIf="order.order_current_status == 109">Cancelled <i class="icofont-check-circled text-success"></i></span>

                                                <span class="float-right text-success" *ngIf="order.order_current_status == 111">Refunded <i class="icofont-check-circled text-success"></i></span>
                                                
                                                <h6 class="mb-2">
                                                    <a [routerLink]="['/restaurant',order.restId._id]" class="text-black">{{order.restId.rest_name}}</a>
                                                </h6>
                                                <p class="text-gray mb-1"><i class="icofont-location-arrow"></i> {{order.restId.rest_address}}
                                                </p>
                                                <p class="text-gray mb-1" *ngIf="order.customer_otp != null"><i class="icofont-mobile-phone"></i> OTP -  {{order.customer_otp}}
                                                </p>
                                                <p class="text-gray mb-3"><i class="icofont-list"></i> ORDER
                                                    #{{order.orderId}} <i class="icofont-clock-time ml-2"></i> {{order.created_at | date:'medium'}}</p>
                                                <p class="text-dark">
                                                    <span *ngFor="let detail of getJson(order.products); let i = index">{{detail.product_name}} x {{detail.qty}}, </span>
                                                </p>
                                                <hr>
                                                <div class="float-right">
                                                    <a *ngIf="order.order_current_status == 108 || order.order_current_status == 111" class="btn btn-sm btn-outline-primary" [routerLink]="['/issue/',order._id]"><i
                                                            class="icofont-headphone-alt"></i> HELP</a>
                                                    <a class="btn btn-sm btn-primary" href="javascript:void(0)" [routerLink]="['/order/',order._id]"><i class="icofont-info-circle"></i> DETAILS</a><a class="btn btn-sm btn-outline-primary" (click)="cancelOrder(order._id)"  *ngIf="order.order_current_status==101 || order.order_current_status==103">Cancel</a>
                                                </div>
                                                <p class="mb-0 text-black text-primary pt-2"><span
                                                        class="text-black font-weight-bold"> Total Paid:</span> {{order.total_paid | currency:'CAD':'symbol-narrow'}}
                                                </p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <ngb-pagination [(page)]="pageOrder" [pageSize]="pageSize" [collectionSize]="myOrderList.length" [maxSize]="5" [rotate]="true" [ellipses]="false" [boundaryLinks]="true" *ngIf="myOrderList.length >0"></ngb-pagination>
                        </div>
                        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            <h4 class="font-weight-bold mt-0 mb-4">My Profile</h4>
                            <div class="row mb-4 pb-2">
                                <div class="col-md-6" >
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">Update Basic Information</h5>
                                            <form [formGroup]="profileForm" (submit)="updateProfile()">
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <label>
                                                            Profile Picture <br/>
                                                            <img [src]="getSanitizerUrl(userData.customer_pic)" class="img img-fluid img-profile rounded-pill">
                                                        </label>
                                                        <input type="file" class="form-control" (change)="onFile($event)" formControlName="customer_pic" accept="file_type | audio/* | video/* | image/* | media_type">
                                                        <small class="form-text" *ngIf="p.customer_name.invalid && (p.customer_name.dirty || p.customer_name.touched)">
                                                            <small *ngIf="p.customer_name.errors.required">Invalid Value</small>
                                                        </small>
                                                        <small class="form-text">{{imageError}}</small>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label>Name
                                                        </label>
                                                        <input type="text" class="form-control" formControlName="customer_name">
                                                        <small class="form-text" *ngIf="p.customer_name.invalid && (p.customer_name.dirty || p.customer_name.touched)">
                                                            <small *ngIf="p.customer_name.errors.required">Invalid Value</small>
                                                        </small>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label>Phone number
                                                        </label>
                                                        <input type="text" class="form-control" formControlName="customer_phone">
                                                        <small class="form-text" *ngIf="p.customer_phone.invalid && (p.customer_phone.dirty || p.customer_phone.touched)">
                                                            <small *ngIf="p.customer_phone.errors.required">Invalid Value</small>
                                                            <small *ngIf="p.customer_phone.errors.pattern">Phone Number is not valid. Please check again.</small>
                                                        </small> 
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <button type="submit" [disabled]="!profileForm.valid" class="btn btn-outline-primary">Update</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6" >
                                    <div class="card shadow-sm">
                                        <div class="card-body">
                                            <h5 class="card-title">Change Password</h5>
                                            <form [formGroup]="formGroup" (submit)="changePassword()">
                                                <div class="form-row">
                                                    <div class="form-group col-md-12">
                                                        <label>Current Password</label>
                                                        <input type="password" class="form-control" formControlName="oldpassword" placeholder="Current Password">
                                                        <small class="form-text" *ngIf="f.oldpassword.invalid && (f.oldpassword.dirty || f.oldpassword.touched)">
                                                            <small *ngIf="f.oldpassword.errors.required">Invalid Value</small>
                                                        </small>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <label>New Password</label>
                                                        <input class="form-control" type="password" placeholder="New Password" formControlName="newpassword">
                                                        <small class="form-text" *ngIf="f.newpassword.invalid && (f.newpassword.dirty || f.newpassword.touched)">
                                                            <small *ngIf="f.newpassword.errors.required">Invalid Value</small>
                                                        </small>
                                                    </div>
                                                    <div class="form-group col-md-12">
                                                        <button class="btn btn-outline-primary" type="submit" [disabled]="!formGroup.valid">UPDATE</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="favourites" role="tabpanel" aria-labelledby="favourites-tab">
                            <h4 class="font-weight-bold mt-0 mb-4">Favourites</h4>
                            <div class="row">
                                <div class="col-md-12 col-sm-12 mb-4 pb-2 text-center" *ngIf="favList.length == 0">
                                    <h6 class="mb-1" class="text-danger">No Restaurant Marked as Favorite.</h6>
                                </div>
                                <div class="col-md-4 col-sm-6 mb-4 pb-2" *ngFor="let fav of favList">
                                    <div
                                        class="list-card bg-white h-100 rounded overflow-hidden position-relative shadow-sm">
                                        <div class="list-card-image">
                                            <div class="star position-absolute"><span class="badge badge-success"><i class="icofont-star"></i> 3.1 (300+)</span></div>
                                            <div class="favourite-heart text-danger position-absolute"><a
                                                    [routerLink]="['/restaurant',fav.restId._id]"><i class="icofont-heart"></i></a></div>
                                            <div class="member-plan position-absolute"><span
                                                    class="badge badge-dark">Promoted</span></div>
                                            <a [routerLink]="['/restaurant',fav.restId._id]">
                                                <img [src]="getSanitizerUrl(fav.restId.logo)" class="img-fluid item-img">
                                            </a>
                                        </div>
                                        <div class="p-3 position-relative">
                                            <div class="list-card-body">
                                                <h6 class="mb-1"><a [routerLink]="['/restaurant',fav.restId._id]" class="text-black">{{fav.restId.rest_name}}
                                                    </a>
                                                </h6>
                                                <!-- <p class="text-gray mb-3">Vegetarian • Indian • Pure veg</p> -->
                                                <p class="text-gray mb-3 time text-center">
                                                    <span
                                                    class="text-black-50"> <a class="btn btn-sm btn-outline-info" [routerLink]="['/restaurant/',fav.restId._id]">ORDER</a></span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="addresses" role="tabpanel" aria-labelledby="addresses-tab">
                            <h4 class="font-weight-bold mt-0 mb-4 d-inline-block">Manage Addresses</h4>
                            <button class="mt-0 mb-4 float-right d-inline-block btn btn-outline-primary" [routerLink]="['/add-address']">Add Address</button>
                            <div class="row w-100">
                                <div class="col-md-12 text-center" *ngIf="addressList.length == 0"><h6 class="text-danger">No Address Available.</h6></div>
                                <div class="col-md-6" *ngFor="let address of addressList">
                                    <div class="bg-white card addresses-item mb-4 shadow" [class.border]="address._id == defaultAddress" [class.border-primary]="address._id == defaultAddress">
                                        <div class="gold-members p-4 h-address">
                                            <div class="media">
                                                <div class="mr-3"><i class="icofont-ui-home icofont-3x"></i></div>
                                                <div class="media-body">
                                                    <h6 class="mb-1 text-secondary">{{address.address_name}}</h6>
                                                    <p class="text-black">{{address.address_full}}</p>
                                                    <p class="mb-0 text-black font-weight-bold">
                                                        <a class="text-primary mr-3" href="javascript:void(0)" [routerLink]="['/edit-address',address._id]" ><i class="icofont-ui-edit"> </i> EDIT</a>
                                                        <a class="text-danger" (click)="changeStatus('Disabled', address._id)" href="javascript:void(0)" *ngIf="address._id != defaultAddress"><i class="icofont-ui-delete"> </i> DELETE</a>
                                                    </p>
                                                    <p class="mt-2 mb-0 text-black font-weight-bold">
                                                        <a class="btn btn-outline-primary" *ngIf="address._id != defaultAddress" (click)="setDefaultAddress(address._id)">Make Default</a>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>