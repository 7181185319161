import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { CheckoutService } from '../shared/checkout/checkout.service';
import { OrderService } from '../shared/order/order.service';
import { RestaurantService } from '../shared/restaurant/restaurant.service';
import { SharedataService } from '../shared/sharedata/sharedata.service';
import { UserauthService } from '../shared/userauth/userauth.service';
import { UserdataService } from '../shared/userdata/userdata.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {

  imageURL = ''
  name: string = ''
  email: string = ''
  myOrderList = []
  isSocial = 'false'

  //Pagination
  page = 1
  pageOrder = 1
  pageTable = 1
  pageWallet = 1
  pageSize = 10

  constructor(private userdata: UserdataService, private toast: ToastrService, private router: Router, private order: OrderService, private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private userauth: UserauthService, private sharedata: SharedataService, private checkout: CheckoutService, private spinner: NgxSpinnerService, private restaurant: RestaurantService) {
    this.imageURL = _imageurl
  }

  ngOnInit(): void {
    this.name = this.userdata.getName()
    this.email = this.userdata.getEmail()
    this.profileForm.patchValue({'customer_email':this.userdata.getEmail()})
    this.formGroup.patchValue({'customer_email':this.userdata.getEmail()})
    this.myOrders()
    this.showFav()
    this.getAllAddress()
    this.getProfile()
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.isSocial = this.userdata.getIsSocial()
  }

  public getSanitizerUrl(photoname) {
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  }

  myOrders() {
    //this.spinner.show();
    this.order.myOrders({ 'customerId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.myOrderList = res.data
        //this.spinner.hide()
        //console.log(this.myOrderList)
      },
      err => {
        //console.log(err)
        //this.spinner.hide()
        this.toast.error('my orders' + err.error.message, 'Error')
      }
    )
  }

  getJson(value) {
    //console.log(value)
    return JSON.parse(value)
  }


  //Show Favorite restaurant
  favList = []
  showFav() {
    this.restaurant.showFav({ 'customerId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.favList = res.data.myfav_rest
        //console.log(this.favList)
      },
      err => {
        //  console.log(err)
        this.toast.error('show fav' + err.error.message, 'Error')
      }
    )
  }

  //Show All address
  addressList = []
  defaultAddress: string = ''
  getAllAddress() {
    this.spinner.show()
    this.userauth.myAddress({ 'customerId': this.userdata.getId() }).subscribe(
      (res: any) => {
        this.addressList = res.data
        this.defaultAddress = res.defaultaddressId
        //console.log(this.addressList,this.defaultAddress)
        this.spinner.hide()
      },
      err => {
        console.log(err)
        this.spinner.hide()
        // this.toast.error('profile'+err.error.message,'Error')
      }
    )
  }

  //--------Enable/Disable Address------//
  changeStatus(val, id) {
    this.spinner.show()
    this.userauth.updateAddress({ 'customerId': this.userdata.getId(), '_id': id, 'status': val }).subscribe(
      (res: any) => {
        this.toast.success(res.message,'Success')
        this.getAllAddress()
        this.spinner.hide()
      },
      err => {
        //console.log(err)
        //this.toast.error(err.error.message,'Error')
        this.getAllAddress()
        this.spinner.hide()
      }
    )
  }

  //--------Set Default Address---------//
  setDefaultAddress(id){
    this.spinner.show()
    //console.log(id,this.userdata.getId())
    this.userauth.setDefaultAddress({'customerId':this.userdata.getId(),'_id':id}).subscribe(
      (res:any) => {
        this.spinner.hide()
        this.getAllAddress()
      },
      err => {
        this.getAllAddress()
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }
  
  //Update profile
  userData:any = ''
  imageError: string
  profileForm = new FormGroup({
    customer_name: new FormControl('', [Validators.required]),
    customer_phone: new FormControl('', [Validators.required,Validators.pattern("[0-9]{10}")]),
    customer_email: new FormControl('', [Validators.required]),
    customer_pic: new FormControl(''),
  });

  get p(){
    return this.profileForm.controls
  }
  
  getProfile(){
    this.userauth.getProfile({'customer_email':this.userdata.getEmail()}).subscribe(
      (res:any) => {
        this.userData = res.data
        this.profileForm.patchValue({'customer_name':this.userData.customer_name})
        this.profileForm.patchValue({'customer_phone':this.userData.customer_phone})
      },
      err => {
        //console.log(err)
        // this.toast.error('profile'+err.error.message,'Error')
      }
    )
  }

  updateProfile(){
    const data = new FormData()
    data.append("customer_phone",this.profileForm.get("customer_phone").value)
    data.append("customer_pic",this.profileForm.get("customer_pic").value)
    data.append("customer_name",this.profileForm.get("customer_name").value)
    data.append("customer_email",this.profileForm.get("customer_email").value)
    this.userauth.updateProfile(data).subscribe(
      (res:any) => {
        this.toast.success(res.message,'Success')
        this.getProfile()
      },
      err => {
        //console.log(err)
        this.toast.error('update '+err.error.message,'Error')
      }
    )
  }

  public onFile(evnt){
    this.imageError = null
    if(evnt.target.files.length > 0)
    {
      const max_size = 2097152
      const allowed_types = ['image/png', 'image/jpeg', 'image/jpg'];
      //const max_size = 1000;
      if (evnt.target.files[0].size > max_size) {
        this.imageError = 'Picture cannot be uploaded as Maximum size allowed is ' + max_size / 1024/1024 + 'MB';
        //this.p.customer_pic.errors.required ='error'
        return false
      }
      else if(!_.includes(allowed_types, evnt.target.files[0].type)){
        this.imageError = 'Only Images are allowed ( JPG | PNG )';
        return false;
      }
      else{
        this.profileForm.patchValue({
          customer_pic: evnt.target.files[0]
        })
      }
    }
  }

  //Update Password
  formGroup = new FormGroup({
    oldpassword: new FormControl('', [Validators.required]),
    newpassword: new FormControl('', [Validators.required]),
    customer_email: new FormControl('', [Validators.required]),
    // confirm_password: new FormControl ('', [Validators.required])
  });

  get f(){
    return this.formGroup.controls
  }

  password(formGroup: FormGroup) {
    const { value: password } = formGroup.get('password');
    const { value: confirm_password } = formGroup.get('confirm_password');
    return password === confirm_password ? null : { passwordNotMatch: true };
  }

  changePassword(){
    this.spinner.show()
    this.userauth.changePassword(this.formGroup.value).subscribe(
      (res:any) => {
        this.spinner.hide()
        if(res.success){
          this.toast.success(res.message,'Success')
          this.formGroup.reset()
        }else{
          this.toast.error(res.message,'Error')
        }
      },
      err => {
        this.spinner.hide()
        //console.log(err)
        this.toast.error('change password'+err.error.message,'Error')
      }
    )
  }

  cancelOrder(event){
    this.order.cancelOrder({'_id':event,'customerId':this.userdata.getId()}).subscribe(
      (res:any) => {
        //console.log(res)
        this.toast.success(res.message,'Success')
        this.myOrders()
      },
      err => {
        //console.log(err)
        this.myOrders()
        this.toast.error(err.error.message,'Error')
      }
    )
  }
}
