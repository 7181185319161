import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SearchService } from '../shared/search/search.service';

@Component({
  selector: 'app-searchrest',
  templateUrl: './searchrest.component.html',
  styleUrls: ['./searchrest.component.css']
})
export class SearchrestComponent implements OnInit {

  imageURL
  constructor(private route:ActivatedRoute, private router: Router, private search:SearchService, private toast: ToastrService,private trusturl: DomSanitizer, @Inject('BASE_IMAGE_URL') _imageurl, private spinner: NgxSpinnerService) { 
    this.imageURL = _imageurl
  }
  
  name
  showRest = []
  ngOnInit(): void {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.name = this.route.snapshot.paramMap.get('name')
    this.getRestaurant()
  }

  getRestaurant(){
    this.spinner.show()
    //console.log(this.name)
    this.search.searchRestaurant({'name':this.name}).subscribe(
      (res:any)=> {
        this.showRest = res.restnames
        this.spinner.hide()
      },
      err => {
        //console.log(err)
        this.spinner.hide()
        this.toast.error(err.error.message,'Error')
      }
    )
  }

  public getSanitizerUrl(photoname){
    return this.trusturl.bypassSecurityTrustUrl(this.imageURL + photoname)
  } 

  //---------Search
  searchForm = new FormGroup({
    searchRest : new FormControl('')
  })
  searchRest(){
    this.name = this.searchForm.get('searchRest').value
    this.getRestaurant()
    //this.router.navigateByUrl('/search/'+this.searchForm.get('searchRest').value)
  }

}
