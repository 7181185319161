<app-topbar-page></app-topbar-page>
<ngx-spinner></ngx-spinner>
<section class="breadcrumb-osahan pt-5 pb-5 bg-dark position-relative text-center">
    <h1 class="text-white">Register your restaurant</h1>
    <h6 class="text-white-50">Best deals at your favourite restaurants</h6>
</section>
<section class="section pt-5 pb-5 products-listing">
    <div class="container">
        <div class="row">
            <div class="col-md-10 m-auto bg-white p-4">
                <h6 class="mb-3 mt-0 mb-3">Register Your Business with us.</h6>
                <form [formGroup]="formGroup" (submit)="onSubmit()">
                    <div class="form-row">
                        <div class="form-group col-md-3">
                            <label for="inputPassword4">Restaurant Name</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter Restaurant Name" name="rest_name" formControlName="rest_name">
                            </div>
                            <small class="form-text"
                                    *ngIf="f.rest_name.invalid && (f.rest_name.dirty || f.rest_name.touched)">
                                    <small *ngIf="f.rest_name.errors.required">Value Required</small>
                                </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputPassword4">Restaurant Phone</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Enter phone" name="rest_phone" formControlName="rest_phone" placeholder="Restaurant Phone">
                            </div>
                            <small class="form-text"
                                    *ngIf="f.rest_phone.invalid && (f.rest_phone.dirty || f.rest_phone.touched)">
                                    <small *ngIf="f.rest_phone.errors.required">Value Required</small>
                                    <small *ngIf="f.rest_phone.errors.pattern">Invalid Phone Number</small>
                                </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputPassword4">Contact Person Name</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="person_name"
                                    placeholder="Enter Contact Person Name" name="person_name"
                                    formControlName="person_name">
                            </div>
                            <small class="form-text"
                                    *ngIf="f.person_name.invalid && (f.person_name.dirty || f.person_name.touched)">
                                    <small *ngIf="f.person_name.errors.required">Value Required</small>
                                </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="inputPassword4">Contact Person Phone</label>
                            <div class="input-group">
                                <input type="text" class="form-control" id="person_phone" placeholder="Enter phone" name="person_phone" formControlName="person_phone">
                            </div>
                            <small class="form-text"
                                    *ngIf="f.person_phone.invalid && (f.person_phone.dirty || f.person_phone.touched)">
                                    <small *ngIf="f.person_phone.errors.required">Value Required</small>
                                    <small *ngIf="f.person_phone.errors.pattern">Invalid Phone Number</small>
                                </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Email</label>
                            <div class="input-group">
                            <input type="email" class="form-control" placeholder="Enter Valid Email" id="person_email" name="person_email" formControlName="person_email">
                        </div>
                            <small class="form-text"
                                *ngIf="f.person_email.invalid && (f.person_email.dirty || f.person_email.touched)">
                                <small *ngIf="f.person_email.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Password
                            </label>
                            <div class="input-group">
                            <input type="password" class="form-control" id="person_password"
                                placeholder="Enter Password" name="person_password" formControlName="person_password">
                            </div>
                            <small class="form-text"
                                *ngIf="f.person_password.invalid && (f.person_password.dirty || f.person_password.touched)">
                                <small *ngIf="f.person_password.errors.required">Invalid
                                    Value</small> </small>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Choose Your Services
                            </label>
                            <div class="input-group">
                                <tag-input [onlyFromAutocomplete]="true" [identifyBy]="'_id'" [displayBy]="'name'"
                                [maxItems]='8' formControlName="services" placeholder="+Services"
                                secondaryPlaceholder="Enter Services">
                                <tag-input-dropdown [autocompleteItems]="serviceList" [showDropdownIfEmpty]="true"
                                    [dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'name'">
                                </tag-input-dropdown>
                            </tag-input>
                            </div>                            

                            <small class="form-text"
                                *ngIf="f.services.invalid && (f.services.dirty || f.services.touched)">
                                <small *ngIf="f.services.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Choose Your Cuisines
                            </label>
                            <div class="input-group">
                                <tag-input [onlyFromAutocomplete]="true" [identifyBy]="'_id'" [displayBy]="'cuisine_name'"
                                [maxItems]='8' formControlName="cuisines" placeholder="+Cuisines"
                                secondaryPlaceholder="Enter Cuisines">
                                <tag-input-dropdown [autocompleteItems]="cuisineList" [showDropdownIfEmpty]="true"
                                    [dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'cuisine_name'">
                                </tag-input-dropdown>
                            </tag-input>
                            </div>                           

                            <small class="form-text"
                                *ngIf="f.cuisines.invalid && (f.cuisines.dirty || f.cuisines.touched)">
                                <small *ngIf="f.cuisines.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-4">
                            <label>Choose Tags
                            </label>
                            <div class="input-group">
                                <tag-input [identifyBy]="'_id'" [displayBy]="'tag_name'" [onlyFromAutocomplete]="true"
                                formControlName="tags" placeholder="+Tags" secondaryPlaceholder="Enter Tag">
                                <tag-input-dropdown [autocompleteItems]="tagList" [showDropdownIfEmpty]="true"
                                    [dynamicUpdate]="false" [identifyBy]="'_id'" [displayBy]="'tag_name'">
                                </tag-input-dropdown>
                            </tag-input>
                            </div>
                            <small class="form-text" *ngIf="f.tags.invalid && (f.tags.dirty || f.tags.touched)">
                                <small *ngIf="f.tags.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Seating Type
                            </label><br />
                            <div class="input-group">
                                <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="setting_type" class="custom-control-input"
                                    id="Takeaway" value="Takeaway" [(ngModel)]="seatingType">
                                <label class="custom-control-label" for="Takeaway">Takeaway</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="setting_type" class="custom-control-input"
                                    id="Din-in" value="Din-in">
                                <label class="custom-control-label" for="Din-in">Din-in</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="setting_type" class="custom-control-input"
                                    id="Both" value="Both">
                                <label class="custom-control-label" for="Both">Both</label>
                            </div>
                            <!-- /radio button -->
                            </div>                            
                            <small class="form-text" *ngIf="f.tags.invalid && (f.tags.dirty || f.tags.touched)">
                                <small *ngIf="f.tags.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label>Payment Type
                            </label><br />
                            <div class="input-group">
                                <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="payment_type" class="custom-control-input"
                                    [(ngModel)]="paymentType" id="Card" value="Card">
                                <label class="custom-control-label" for="Card">Card</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="payment_type" class="custom-control-input"
                                    id="Cash" value="Cash">
                                <label class="custom-control-label" for="Cash">Cash</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" formControlName="payment_type" class="custom-control-input"
                                    id="Both_payment" value="Both_payment">
                                <label class="custom-control-label" for="Both_payment">Both</label>
                            </div>
                            <!-- /radio button -->
                            </div>
                            
                            <small class="form-text"
                                *ngIf="f.payment_type.invalid && (f.payment_type.dirty || f.payment_type.touched)"><small
                                    *ngIf="f.payment_type.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label>Timings</label>
                            <table class="table table-borderless table-sm">
                                <tr>
                                    <th>Day</th>
                                    <th>Start Time</th>
                                    <th>End Time</th>
                                    <th>Closed</th>
                                </tr>
                                <tr>
                                    <td>Monday</td>
                                    <td><select class="form-control" id="start_monday" name="start_monday"
                                            formControlName="start_monday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_monday" name="end_monday"
                                            formControlName="end_monday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td> <input type="checkbox" name="monday" id="monday" value="CLOSED"
                                            formControlName="monday" (change)="checkTimingStatus('monday',$event)">
                                        CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Tuesday</td>
                                    <td><select class="form-control" id="start_tuesday" name="start_tuesday"
                                            formControlName="start_tuesday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_tuesday" name="end_tuesday"
                                            formControlName="end_tuesday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td> <input type="checkbox" name="tuesday" id="tuesday" value="CLOSED"
                                            formControlName="tuesday" (change)="checkTimingStatus('tuesday',$event)">
                                        CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Wednesday</td>
                                    <td><select class="form-control" id="start_wednesday" name="start_wednesday"
                                            formControlName="start_wednesday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_wednesday" name="end_wednesday"
                                            formControlName="end_wednesday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><input type="checkbox" name="wednesday" id="wednesday" value="CLOSED"
                                            formControlName="wednesday"
                                            (change)="checkTimingStatus('wednesday',$event)"> CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Thursday</td>
                                    <td><select class="form-control" id="start_thursday" name="start_thursday"
                                            formControlName="start_thursday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_thursday" name="end_thursday"
                                            formControlName="end_thursday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><input type="checkbox" name="thursday" id="thursday" value="CLOSED"
                                            formControlName="thursday" (change)="checkTimingStatus('thursday',$event)">
                                        CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Friday</td>
                                    <td><select class="form-control" id="start_friday" name="start_friday"
                                            formControlName="start_friday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_friday" name="end_friday"
                                            formControlName="end_friday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><input type="checkbox" name="friday" id="friday" value="CLOSED"
                                            formControlName="friday" (change)="checkTimingStatus('friday',$event)">
                                        CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Saturday</td>
                                    <td><select class="form-control" id="start_saturday" name="start_saturday"
                                            formControlName="start_saturday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_saturday" name="end_saturday"
                                            formControlName="end_saturday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><input type="checkbox" name="saturday" id="saturday" value="CLOSED"
                                            formControlName="saturday" (change)="checkTimingStatus('saturday',$event)">
                                        CLOSED</td>
                                </tr>
                                <tr>
                                    <td>Sunday</td>
                                    <td><select class="form-control" id="start_sunday" name="start_sunday"
                                            formControlName="start_sunday">
                                            <option value="" [selected]="true" [disabled]="true">From</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><select class="form-control" id="end_sunday" name="end_sunday"
                                            formControlName="end_sunday">
                                            <option value="" [selected]="true" [disabled]="true">To</option>
                                            <!-- <option *ngFor="let timing of timingList" [value]="timing.timming_name">{{timing.timming_name}}</option> -->
                                            <option *ngFor="let timing of timingStat" [value]="timing">{{timing}}:00
                                            </option>
                                        </select></td>
                                    <td><input type="checkbox" name="sunday" id="sunday" value="CLOSED"
                                            formControlName="sunday" (change)="checkTimingStatus('sunday',$event)">
                                        CLOSED</td>
                                </tr>
                            </table>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Restaurant Logo</label>
                            <input type="file" accept="image/*" class="form-control" id="logo" name="logo" (change)="logoFile($event)" formControlName="logo">
                            <small class="form-text" *ngIf="f.logo.invalid && (f.logo.dirty || f.logo.touched)">
                                <small *ngIf="f.logo.errors.required">Value Required</small>

                            </small>
                            <small class="form-text">{{imageError}}</small>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Owner ID Proof</label>
                            <input type="file" accept="image/*" class="form-control" id="person_id_proof" name="person_id_proof" (change)="proofFile($event)" formControlName="person_id_proof">
                            <small class="form-text"
                                *ngIf="f.person_id_proof.invalid && (f.person_id_proof.dirty || f.person_id_proof.touched)"><small
                                    *ngIf="f.person_id_proof.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label>Tax Proof</label>
                            <input type="file" accept="image/*" class="form-control" id="tax_proof" name="tax_proof" (change)="taxFile($event)" formControlName="tax_proof">
                            <small class="form-text"
                                *ngIf="f.tax_proof.invalid && (f.tax_proof.dirty || f.tax_proof.touched)">
                                <small *ngIf="f.tax_proof.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-3">
                            <label for="sfcr_proof">SFCR Proof</label>
                            <input type="file" accept="image/*" class="form-control" id="sfcr_proof" name="sfcr_proof" (change)="sfcrFile($event)" formControlName="sfcr_proof">
                            <small class="form-text"
                                *ngIf="f.sfcr_proof.invalid && (f.sfcr_proof.dirty || f.sfcr_proof.touched)">
                                <small *ngIf="f.sfcr_proof.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="rest_street_number">Address in Documents</label>
                            <textarea class="form-control" id="address_in_docs" placeholder="Enter Address in Documents" name="address_in_docs" formControlName="address_in_docs"></textarea>
                            <small class="form-text"
                                *ngIf="f.address_in_docs.invalid && (f.address_in_docs.dirty || f.address_in_docs.touched)">
                                <small *ngIf="f.address_in_docs.errors.required">Invalid
                                    Value</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="rest_street_number">Is Tax Payable?</label><br />
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="tax" class="custom-control-input" id="tax_true"
                                    value="tax_true" [(ngModel)]="tax" (change)="taxValue($event)" [ngModelOptions]="{standalone: true}">
                                <label class="custom-control-label" for="tax_true">Yes</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="tax" class="custom-control-input" id="tax_false"
                                    value="tax_false" (change)="taxValue($event)">
                                <label class="custom-control-label" for="tax_false">No</label>
                            </div>
                            <!-- /radio button -->
                            <small class="form-text"
                                *ngIf="f.is_tax.invalid && (f.is_tax.dirty || f.is_tax.touched)"><small *ngIf="f.is_tax.errors.required">Value Required</small> </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="rest_street_number">Is SFCR?</label><br />
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="sfrc" class="custom-control-input" id="sfcr_true" value="sfcr_true" [(ngModel)]="sfcr" (change)="sfcrValue($event)" [ngModelOptions]="{standalone: true}">
                                <label class="custom-control-label" for="sfcr_true">Yes</label>
                            </div>
                            <!-- /radio button -->
                            <!-- Radio Button -->
                            <div class="custom-control custom-radio custom-control-inline">
                                <input type="radio" name="sfrc" class="custom-control-input" id="sfcr_false" value="sfcr_false" (change)="sfcrValue($event)">
                                <label class="custom-control-label" for="sfcr_false">No</label>
                            </div>
                            <!-- /radio button -->
                            <small class="form-text"
                                *ngIf="f.is_sfcr.invalid && (f.is_sfcr.dirty || f.is_sfcr.touched)"><small
                                    *ngIf="f.is_sfcr.errors.required">Value Required</small> </small>
                        </div>
                        <div class="form-group col-md-6" *ngIf="tax == 'tax_true'">
                            <label for="rest_street_number">Tax Number</label>
                            <input type="text" class="form-control" id="tax_number" placeholder="Enter Tax Number"
                                name="tax_number" formControlName="tax_number">
                            <small class="form-text"
                                *ngIf="f.tax_number.invalid && (f.tax_number.dirty || f.tax_number.touched)">
                                <small *ngIf="f.tax_number.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6" *ngIf="tax == 'tax_true'">
                            <label for="rest_street_number">Tax Registration Date</label>
                            <input type="date" class="form-control" id="tax_reg_date"
                                placeholder="Enter Tax Registration Date" name="tax_reg_date"
                                formControlName="tax_reg_date">
                            <small class="form-text"
                                *ngIf="f.tax_reg_date.invalid && (f.tax_reg_date.dirty || f.tax_reg_date.touched)">
                                <small *ngIf="f.tax_reg_date.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6" *ngIf="sfcr == 'sfcr_true'">
                            <label for="rest_street_number">SFCR Number</label>
                            <input type="text" class="form-control" id="tax_number" placeholder="Enter SFCR Number"
                                name="sfcr_number" formControlName="sfcr_number">
                            <small class="form-text"
                                *ngIf="f.sfcr_number.invalid && (f.sfcr_number.dirty || f.sfcr_number.touched)">
                                <small *ngIf="f.sfcr_number.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6" *ngIf="sfcr == 'sfcr_true'">
                            <label for="rest_street_number">SFCR Expiry</label>
                            <input type="date" class="form-control" id="tax_number" placeholder="Enter SFCR Number"
                                name="sfcr_expiry" formControlName="sfcr_expiry">
                            <small class="form-text"
                                *ngIf="f.sfcr_expiry.invalid && (f.sfcr_expiry.dirty || f.sfcr_expiry.touched)">
                                <small *ngIf="f.sfcr_expiry.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="rest_city">City</label>
                            <select class="form-control" id="rest_city" name="rest_city" formControlName="rest_city"
                                [(ngModel)]="defaultOption">
                                <option [value]=null>Select City</option>
                                <option *ngFor="let city of cityList" [value]="city._id">
                                    {{city.city_name}}</option>
                            </select>
                            <small class="form-text"
                                *ngIf="f.rest_city.invalid && (f.rest_city.dirty || f.rest_city.touched)">
                                <small *ngIf="f.rest_city.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-6">
                            <label for="rest_street_number">Street Number</label>
                            <input type="text" class="form-control" id="rest_street_number"
                                placeholder="Enter Street Number" name="rest_street_number"
                                formControlName="rest_street_number"> <small class="form-text"
                                *ngIf="f.rest_street_number.invalid && (f.rest_street_number.dirty || f.rest_street_number.touched)">
                                <small *ngIf="f.rest_street_number.errors.required">Invalid
                                    Value</small> </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="inputPassword4">Search Address</label>
                            <div class="input-group">
                                <input type="text" class="form-control" placeholder="Search Address" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                                <div class="input-group-append">
                                    <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="setCurrentLocation()"><i class="icofont-ui-pointer"></i></button>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col-md-12">
                            <label for="rest_address">Address</label>
                            <input type="text" class="form-control" placeholder="Complete Address" name="rest_address" id="rest_address" formControlName="rest_address" [readonly]="true">
                            <small class="form-text"
                                *ngIf="f.rest_address.invalid && (f.rest_address.dirty || f.rest_address.touched)">
                                <small *ngIf="f.rest_address.errors.required">Value Required</small>
                            </small>
                        </div>
                        <div class="form-group col-md-12">
                            <label>Location</label>
                            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true" (dragEnd)="markerDragEnd($event)"></agm-marker>
                            </agm-map>
                        </div>
                        <div class="form-group col-md-12">
                            <div class="custom-control custom-checkbox">
                                <input type="checkbox" (change)="checkTerms($event)">
                                <label for="customCheck1"> &nbsp;I accept <a [routerLink]="['/terms']">Terms & Conditions</a> of VegFood.</label>
                            </div>
                        </div>
                        <div class="form-group col-md-12 mb-0">
                            <button type="submit" class="btn btn-success btn-block btn-lg" [disabled]="formGroup.invalid">SUBMIT <i class="icofont-long-arrow-right"></i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<app-footer-page></app-footer-page>