<div class="container-fluid">
    <div class="row no-gutter">
        <div class="d-none d-md-flex col-md-4 col-lg-6 bg-image"></div>
        <div class="col-md-8 col-lg-6">
            <div class="login d-flex align-items-center py-5">
                <div class="container">
                    <div class="row">
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5" *ngIf="!isOtpSent">
                            <h3 class="login-heading mb-4">Forget Password!</h3>
                            <form [formGroup]="formGroup" (submit)="onSubmit()">
                                <div class="form-label-group">
                                    <input type="email" id="inputEmail" class="form-control"
                                        placeholder="Email address" formControlName="customer_email">
                                    <label for="inputEmail">Email address</label>     
                                </div>                                                          
                                <button type="submit" class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" [disabled]="!formGroup.valid">Send OTP</button>
                                <div class="alert alert-danger" *ngIf="isEmailError">{{emailError}}</div>
                            </form>
                             
                        </div>
                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5" *ngIf="isOtpSent && !isOtpVerify">
                            <h3 class="login-heading mb-4">Enter OTP</h3>
                            <div>
                                <ng-otp-input  (onInputChange)="onOtpChange($event)"  [config]="{length:4}"></ng-otp-input>
                                <div class="alert alert-danger mt-2" *ngIf="isOtpError">OTP Verification Failed!!</div>
                            </div>    
                        </div>

                        <div class="col-md-9 col-lg-8 mx-auto pl-5 pr-5" *ngIf="isOtpVerify">
                            <h3 class="login-heading mb-4">Change Password !</h3>
                            <form [formGroup]="passwordGroup" (submit)="changePassword()">
                                <div class="form-label-group">
                                    <input type="password" id="password" class="form-control"
                                        placeholder="Password" formControlName="customer_password">
                                    <label for="password">Password</label>   
                                    <small class="form-text" *ngIf="f.customer_password.invalid && (f.customer_password.dirty || f.customer_password.touched)">
                                        <small *ngIf="f.customer_password.errors">Minimum 6 characters required.</small>
                                    </small>  
                                </div>
                                <div class="form-label-group">
                                    <input type="password" id="confirm" class="form-control"
                                        placeholder="Confirm Password" formControlName="confirm_password" (keyup)="passwordWork()">
                                    <label for="confirm">Confirm Password</label>     
                                </div> 
                                <button type="submit" class="btn btn-lg btn-outline-primary btn-block btn-login text-uppercase font-weight-bold mb-2" [disabled]="!passwordGroup.valid || !passwordBoolean">Change Password</button> 
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>