<nav class="navbar navbar-expand-lg navbar-light bg-light osahan-nav shadow-sm">
    <div class="container">
        <a class="navbar-brand" [routerLink]="['/home']">
            <img alt="Veggie Lyfe" src="../../assets/img/Veggie Lyde.png" class="logo-pic">
            <!-- VEG <span class="text-danger">Food</span> -->
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a class="nav-link" [routerLink]="['/home']">Home <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/offers']"><i class="icofont-sale-discount"></i> Offers <span
                            class="badge badge-danger">New</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" [routerLink]="['/restaurants']"><i class="icofont-restaurant"></i> Restaurants</a>
                </li>
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Restaurants
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                        <a class="dropdown-item" href="listing.html">Listing</a>
                        <a class="dropdown-item" href="detail.html">Detail + Cart</a>
                        <a class="dropdown-item" href="checkout.html">Checkout</a>
                    </div>
                </li> -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        Pages
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                        <a class="dropdown-item" href="track-order.html">Track Order</a>
                        <a class="dropdown-item" href="invoice.html">Invoice</a>
                        <a class="dropdown-item" href="login.html">Login</a>
                        <a class="dropdown-item" href="register.html">Register</a>
                        <a class="dropdown-item" href="404.html">404</a>
                        <a class="dropdown-item" href="extra.html">Extra :)</a>
                    </div>
                </li> -->
                <!-- <li class="nav-item" *ngIf="loggedIn == 1">
                    <a class="nav-link" [routerLink]="['/account']"><img alt="Generic placeholder image" src="../../assets/img/user/4.png"
                        class="nav-osahan-pic rounded-pill"> My Account</a>
                </li> -->
                <li class="nav-item dropdown" *ngIf="loggedIn == 1">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <img alt="Generic placeholder image" src="../../assets/img/user/4.png"
                            class="nav-osahan-pic rounded-pill"> My Account
                    </a>
                    <div class="dropdown-menu dropdown-menu-right shadow-sm border-0">
                        <a class="dropdown-item" [routerLink]="['/account']"><i class="icofont-food-cart"></i>
                            Account</a>
                        <a class="dropdown-item" (click)="logOut()" href="javascript:void(0)"><i class="icofont-logout"></i> Logout</a>
                    </div>
                </li>
                <li class="nav-item" *ngIf="loggedIn == 0">
                    <a class="nav-link" [routerLink]="['/login']"><i class="icofont-login"></i> Login</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" (click)="checkOutPage()" href="javascript:void(0)"><i class="fas fa-shopping-basket"></i> Cart</a>
                </li>
                <!-- <li class="nav-item dropdown dropdown-cart">
                    <a class="nav-link dropdown-toggle" href="javascript:void(0)" role="button" data-toggle="dropdown"
                        aria-haspopup="true" aria-expanded="false">
                        <i class="fas fa-shopping-basket"></i> Cart
                        <span class="badge badge-success">5</span>
                    </a>
                    <div class="dropdown-menu dropdown-cart-top p-0 dropdown-menu-right shadow-sm border-0">
                        <div class="dropdown-cart-top-header p-4">
                            <img class="img-fluid mr-3" alt="osahan" src="../../assets/img/cart.jpg">
                            <h6 class="mb-0">Gus's World Famous Chicken</h6>
                            <p class="text-secondary mb-0">310 S Front St, Memphis, USA</p>
                            <small><a class="text-primary font-weight-bold" href="javascript:void(0)">View Full Menu</a></small>
                        </div>
                        <div class="dropdown-cart-top-body border-top p-4">
                            <p class="mb-2"><i class="icofont-ui-press text-danger food-item"></i> Chicken Tikka Sub
                                12" (30 cm) x 1 <span class="float-right text-secondary">$314</span></p>
                            <p class="mb-2"><i class="icofont-ui-press text-success food-item"></i> Corn & Peas
                                Salad x 1 <span class="float-right text-secondary">$209</span></p>
                            <p class="mb-2"><i class="icofont-ui-press text-success food-item"></i> Veg Seekh Sub 6"
                                (15 cm) x 1 <span class="float-right text-secondary">$133</span></p>
                            <p class="mb-2"><i class="icofont-ui-press text-danger food-item"></i> Chicken Tikka Sub
                                12" (30 cm) x 1 <span class="float-right text-secondary">$314</span></p>
                            <p class="mb-2"><i class="icofont-ui-press text-danger food-item"></i> Corn & Peas Salad
                                x 1 <span class="float-right text-secondary">$209</span></p>
                        </div>
                        <div class="dropdown-cart-top-footer border-top p-4">
                            <p class="mb-0 font-weight-bold text-secondary">Sub Total <span
                                    class="float-right text-dark">$499</span></p>
                            <small class="text-info">Extra charges may apply</small>
                        </div>
                        <div class="dropdown-cart-top-footer border-top p-2">
                            <a class="btn btn-success btn-block btn-lg" href="checkout.html"> Checkout</a>
                        </div>
                    </div>
                </li> -->
            </ul>
        </div>
    </div>
</nav>