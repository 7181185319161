import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { UserdataService } from '../userdata/userdata.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  BaseUrl = ''
  Token = ''

  constructor(private http: HttpClient, @Inject('BASE_URL') _base,private userdata: UserdataService) {
    this.BaseUrl = _base
    this.Token = userdata.getToken()
  }

  public getDeliveryCharges(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/fetchsingledcharges",form, { headers: header_object})
  }

  public getOffers(form){
    return this.http.post(this.BaseUrl + "/allOfferspublic",form)
  }

  public placeOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/addneworder",form, { headers: header_object})
  }

  public checkCoupon(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/checkcoupon",form, { headers: header_object})
  }

  public calculateDistance(form){
    //return this.http.get("https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins="+pickLat +"," +pickLong +"&destinations=" +dropLat +"," +dropLong + "&key=AIzaSyBLLzgwCEI4hVTDKZ0nX8NJiod9VmiIThQ")
    //return this.http.get("https://maps.googleapis.com/maps/api/distancematrix/json?units=imperial&origins=31.3137491,75.5887982&destinations=31.320104,75.599808&key=AIzaSyBLLzgwCEI4hVTDKZ0nX8NJiod9VmiIThQ")
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/distance",form, { headers: header_object})
  }

  public placeOnlineOrder(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/payment",form, { headers: header_object})
  }

  public checkRestaurantOpen(form){
    var header_object = new HttpHeaders().set("Authorization",this.userdata.getToken())
    return this.http.post(this.BaseUrl + "/checkrestopenornot",form, { headers: header_object})
  }
}
