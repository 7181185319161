export class Cart {
    pid: string;
    product_name: string
    qty: number
    pprice: string
    catid: string
    addOn: AddonData[]
    public setCart(pid, product_name, qty, addon, catid){
        this.pid = pid
        this.product_name = product_name
        this.qty = qty
        this.addOn = addon
        this.catid = catid
    }
}

export class AddonData {
    addontype: string
    value: Value[]
}

export class Value {
    valuetype: string
    valuesvalue: string
}